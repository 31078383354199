import React from 'react';
import { Header } from '..';
import { InfoIcon, LoopGreenLogo } from '../../../assets/img';
import {
    StyledContainer,
    StyledDetailsSection,
    StyledOrderSection,
    StyledHeadingSection,
    StyledWrapper,
    StyledTopUpPolicyCardWrapper,
    StyledMemberWrapper,
    StyledMemberHeading,
    StyledMemberHeadingSpan,
    StyledNameContainer,
    StyledOrderHeadingWrapper,
    StyledOrderHeading,
    StyledOrderWrapper,
    StyledPremiumWrapper,
    StyledTotalAmountWrapper,
    StyledTotalAmountLabel,
    StyledTotalAmountSpan,
    StyledSection,
    StyledDiscountSpan,
    StyledPremiumLabel
} from './styles';

import { REDIRECT_URL } from '../../../utils/constants/RedirectConstants';
import BeyondInsurance from '../../atoms/BeyondInsurance';
import { redirect } from '../../../utils/common';
import InsuranceIdentity from '../../atoms/InsuranceIdentity';
import TopUpPolicyCard from '../../atoms/TopUpPolicyCard';
import { ReduxState } from '../../../redux';
import { useSelector } from 'react-redux';
import MemberIdentity from '../../atoms/MemberIdentity';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import useSegment from '../../../utils/hooks/useSegment';
import { TextView } from '../../atoms/TextView';
import theme from '../../../theme';
import Tooltip from '../../atoms/Tooltip';
const TopupSummary: React.FunctionComponent = () => {
    const policyDetails: any = useSelector((state: ReduxState) => state.TopUp_policyData.policyList.data);
    const basePolicyData = policyDetails?.baseGmcPolicyData;
    const topupPolicyDetails = policyDetails?.topUpPolicyData;
    const annualPremiumWithGST = topupPolicyDetails?.annualPremium;
    const trackClick = useSegment('click');
    const redirectToStore = (url: string) => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.CONFIRMATION_REDIRECT_TO_STORE;
        const store = url === REDIRECT_URL.APP_STORE ? 'App' : 'Play';
        trackClick({
            name: segmentAction.name,
            properties: {
                describe: (segmentAction.properties?.describe as (store: string) => string)(store) || '',
                store: (segmentAction.properties?.store as (store: string) => string)(store) || ''
            }
        });
        redirect(url);
    };
    return (
        <>
            <Header logo={LoopGreenLogo} bgColor={false} />
            <StyledContainer>
                <StyledHeadingSection>
                    <BeyondInsurance
                        redirectToAppStore={() => redirectToStore(REDIRECT_URL.APP_STORE)}
                        redirectToPlayStore={() => redirectToStore(REDIRECT_URL.PLAY_STORE)}
                        source=''
                        sumInsured={topupPolicyDetails.sumInsured}
                    />
                </StyledHeadingSection>
                <StyledWrapper>
                    <StyledSection>
                        <StyledOrderHeading>TOP UP SUMMARY</StyledOrderHeading>
                        <StyledDetailsSection>
                            <InsuranceIdentity
                                policyCategory={'Loop Super Top-up Policy'}
                                policyName={'ICICI Lombard General Insurance Co. Ltd.'}
                                policyImage={topupPolicyDetails?.policyImage || ''}
                                textColor={'beyondGrey'}
                            />
                            <StyledTopUpPolicyCardWrapper>
                                <TopUpPolicyCard
                                    coveredMembers={topupPolicyDetails?.familyStructure || ''}
                                    sumInsured={topupPolicyDetails.sumInsured}
                                    deductible={basePolicyData.sumInsured}
                                />
                            </StyledTopUpPolicyCardWrapper>
                            <StyledMemberWrapper>
                                <StyledMemberHeading>
                                    <StyledMemberHeadingSpan>Bought For</StyledMemberHeadingSpan>
                                </StyledMemberHeading>
                                <StyledNameContainer>
                                    {topupPolicyDetails?.dependents?.map(
                                        (
                                            dep: {
                                                firstName: string;
                                                lastName: string;
                                                relationship: string;
                                                userId: string;
                                            },
                                            index: number
                                        ) => {
                                            return (
                                                <MemberIdentity
                                                    key={index}
                                                    firstName={dep.firstName}
                                                    lastName={dep.lastName}
                                                    relationship={dep.relationship}
                                                    index={index}
                                                    bgColor={'white'}
                                                />
                                            );
                                        }
                                    )}
                                </StyledNameContainer>
                            </StyledMemberWrapper>
                        </StyledDetailsSection>
                    </StyledSection>
                    <StyledOrderSection>
                        <StyledOrderHeadingWrapper>
                            <StyledOrderHeading>PAYMENT SUMMARY</StyledOrderHeading>
                            <StyledOrderWrapper>
                                {/* <StyledPremiumWrapper>
                                    <StyledPremiumLabel>Annual Premium</StyledPremiumLabel>
                                    <StyledPremiumSpan>₹{annualPremiumWithoutGST}</StyledPremiumSpan>
                                </StyledPremiumWrapper> */}
                                {/* <StyledPremiumWrapper>
                                    <StyledPremiumLabel>
                                        {topupPolicyDetails?.gstPercentage || '18'}% GST
                                    </StyledPremiumLabel>
                                    <StyledPremiumSpan>₹{gstAmount}</StyledPremiumSpan>
                                </StyledPremiumWrapper> */}
                                <StyledTotalAmountWrapper>
                                    <StyledTotalAmountLabel>
                                        Total Annual Premium
                                        <br />
                                        <TextView
                                            fontSize='14px'
                                            margin='10px 0px 0px 0px'
                                            color={theme.colors.beyondGrey}
                                        >
                                            (Inclusive of GST and service charge)
                                        </TextView>
                                    </StyledTotalAmountLabel>
                                    <StyledTotalAmountSpan>
                                        ₹{annualPremiumWithGST?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                                    </StyledTotalAmountSpan>
                                </StyledTotalAmountWrapper>
                                {topupPolicyDetails?.digitPremiumWithoutGST && (
                                    <StyledPremiumWrapper>
                                        <StyledPremiumLabel>Amount for tax savings</StyledPremiumLabel>
                                        <StyledDiscountSpan>
                                            ₹
                                            {topupPolicyDetails?.digitPremiumWithoutGST >= 25000
                                                ? 25000
                                                : topupPolicyDetails?.digitPremiumWithoutGST}
                                        </StyledDiscountSpan>
                                        <Tooltip
                                            Icon={InfoIcon}
                                            Text={
                                                // eslint-disable-next-line max-len
                                                'This is the total amount you can save under Income Tax Section 80D after purchasing this policy. Maximum limit is ₹25,000.'
                                            }
                                        />
                                    </StyledPremiumWrapper>
                                )}
                            </StyledOrderWrapper>
                        </StyledOrderHeadingWrapper>
                    </StyledOrderSection>
                </StyledWrapper>
            </StyledContainer>
        </>
    );
};

export default TopupSummary;
