import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 400px;
    @media (max-width: 768px) {
        min-width: initial;
    }
`;
export const Spacing = styled.div`
    min-width: 16px;
    margin-bottom: 20px;
    @media (max-width: 1204px) {
        min-width: 100%;
        min-height: 16px;
    }
`;
