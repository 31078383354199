import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import { LoginPage, OTPPage } from './components/pages';
import firebase from 'firebase';
import { ILoginContainer } from './types';
import {
    StyledMainContainer,
    StyledContainer,
    StyledRightContainer,
    StyledHeadingContainer,
    StyledHeadingText,
    StyledSignInText,
    StyledSignInSubText,
    StyledPhoneNumContainer,
    StyledFooterContainer,
    StyledFooterText,
    StyledSectionContainer
} from './styles';
import { getUserDetails, checkUserAccess } from './redux/slices/UserSlice/thunks';
import { ReduxState } from '../../../redux';
import useToken from './utils/hooks/useToken';
import { ErrorContainer } from '../../atoms';
import { Loader } from './components/atoms';
import { Header } from '../../containers';
import theme from '../../../theme';
import { LoopGreenLogo, LoopIconGreen } from '../../../assets/img';
import useSegment from '../../../utils/hooks/useSegment';
import { TextView } from '../../atoms/TextView';
import AllowedUsers from './components/containers/AllowedUsers';
declare global {
    interface Window {
        recaptchaVerifier: firebase.auth.RecaptchaVerifier;
        confirmationResult: firebase.auth.ConfirmationResult | null;
        analytics: any;
    }
}
const LoginContainer: React.FunctionComponent<ILoginContainer> = ({
    PanelComponent,
    title,
    subTitle,
    authTokenName,
    redirectTo
}) => {
    // const navigate = useHistory();
    const dispatch = useDispatch();
    const authToken = useToken(authTokenName);
    const userMobile = useSelector((state: ReduxState) => state.TopUp_user.userMobile);
    const userData = useSelector((state: any) => state.TopUp_user.userData);
    const authenticationError = useSelector((state: any) => state.TopUp_user.isUserAuthenticated.error);
    const [isPersistanceLoading, setIsPersistanceLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const [displayLogin, setDisplayLogin] = React.useState(true);

    const [permissionErr, setPermissionErr] = React.useState(false);
    const [displayOTP, setDisplayOTP] = React.useState(false);
    const trackLogin = useSegment('identify');

    // check for persistance
    React.useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                // User is signed in.
                if (!userData.data) {
                    const email = user.email;
                    const mobile = user.phoneNumber;
                    if (email) {
                        setIsPersistanceLoading(true);
                        dispatch(getUserDetails({ key: 'email', value: email }));
                    } else if (mobile) {
                        setIsPersistanceLoading(true);
                        dispatch(getUserDetails({ key: 'mobile', value: mobile.slice(-10) }));
                    }
                }
            }
        });
    }, [firebase]);

    // Remove the loading screen when an error is received and display the error
    React.useEffect(() => {
        setIsPersistanceLoading(false);
        if (authenticationError?.message === 'User does not have permission to access this application') {
            setPermissionErr(true);
        }
    }, [userData.error, authenticationError]);

    // trigger when user has entered mobile number
    // when user enters mobile number, userMobile is set in redux state, thus trigerring below useEffect
    React.useEffect(() => {
        // if user has entered mobile number, redirect to OTP page
        if (window.confirmationResult && userMobile.data?.length === 10) {
            if (!displayOTP) {
                setDisplayLogin(false);
                setDisplayOTP(true);
            }
        }
    }, [window.confirmationResult, userMobile, history]);
    // trigger only if we have aquired userData from firestore
    // 1. When OTP verification is successful
    // 2. When OAuth Login is successful
    React.useEffect(() => {
        if (userData && userData.data) {
            const mobile = userData.data.mobile;
            const email = userData.data.email;
            if (mobile || email) {
                authToken
                    .generateFirebaseToken()
                    .then(() => {
                        dispatch(
                            checkUserAccess({
                                redirectFunction: redirectTo,
                                roles: userData.data.roles,
                                setIsLoading: setIsPersistanceLoading,
                                trackLogin
                            })
                        );
                    })
                    .catch(() => {
                        setError('Unable to login user at this time. Please refresh the page and try again.');
                    });
            }
        }
    }, [history, userData]);

    const showLoginPage = () => {
        setDisplayLogin(true);
        setDisplayOTP(false);
    };

    return permissionErr ? (
        <StyledSectionContainer>
            <Header bgColor={false} logo={LoopGreenLogo} />
            <AllowedUsers />
        </StyledSectionContainer>
    ) : (
        <StyledMainContainer>
            <Header bgColor={true} logo={LoopIconGreen} />
            <StyledContainer>
                <PanelComponent />
                <StyledRightContainer>
                    <StyledHeadingContainer>
                        {displayLogin && (
                            <StyledHeadingText>
                                Access your <StyledSignInText>policy details</StyledSignInText>
                            </StyledHeadingText>
                        )}

                        {displayOTP && (
                            <StyledHeadingText>
                                <StyledSignInText>Verify </StyledSignInText>with OTP
                            </StyledHeadingText>
                        )}
                        {displayLogin && (
                            <StyledSignInSubText>
                                <TextView fontSize='16px' margin='0px 0px 0px 0px' color={theme.colors.loopEmerald}>
                                    Sign In with your phone number/email address registered with Loop
                                </TextView>
                            </StyledSignInSubText>
                        )}
                    </StyledHeadingContainer>
                    <StyledPhoneNumContainer>
                        {isPersistanceLoading || userData.loading ? (
                            <Loader />
                        ) : (
                            <>
                                {(displayLogin && <LoginPage title={title} subTitle={subTitle} />) || <></>}
                                {displayOTP && <OTPPage goBack={showLoginPage} mobile={userMobile.data || ''} />}
                            </>
                        )}
                    </StyledPhoneNumContainer>
                    {!!error?.length && <ErrorContainer message={error} />}
                    {!!authenticationError && <ErrorContainer message={authenticationError.message} />}
                    <StyledFooterContainer>
                        <StyledFooterText>
                            Not a Loop Member yet? Stay tuned! Our team is working hard to ensure everyone can get these
                            benefits.
                        </StyledFooterText>
                    </StyledFooterContainer>
                </StyledRightContainer>
            </StyledContainer>
        </StyledMainContainer>
    );
};

export default LoginContainer;
