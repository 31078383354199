import React from 'react';
import { Switch } from 'react-router-dom';
import { INTERNAL_ROUTES } from '../../../utils/constants/RouteConstants';
import { InternalRouteGuard } from '../../../utils/RouteGuards';
import { DashboardPage, SummaryPage } from './pages';
const InternalRouter: React.FunctionComponent = () => {
    return (
        <Switch>
            <InternalRouteGuard path={INTERNAL_ROUTES.dashboard} Component={DashboardPage} exact />
            <InternalRouteGuard path={INTERNAL_ROUTES.summary} Component={SummaryPage} exact />
        </Switch>
    );
};

export default InternalRouter;
