import { createAsyncThunk } from '@reduxjs/toolkit';
import firebase from 'firebase';
import { getAppPermissionID, getRolePermissions, getUserData } from '../../../adapters';
import { FirebaseCaptcha, FirebaseAuth } from '../../../../../../adapters/provider';
import { IOAuthType } from '../../types';
import { SEGMENT_ACTIONS } from '../../../../../../utils/constants/SegmentActionConstants';
export const USER_ACTION_TYPES = {
    LOGIN_WITH_MOBILE: 'userdata/loginWithMobile',
    VERIFY_OTP: 'userData/verifyOTP',
    FETCH_USER: 'userdata/fetchUser',
    UPDATE_USER: 'userdata/updateData',
    LOGIN_WITH_OAUTH: 'userdata/loginWithOAuth',
    FETCH_USER_PROFILE: 'userdata/fetchUserProfile',
    CHECK_USER_ACCESS: 'userdata/checkUserAccess'
};

export const loginWithMobile = createAsyncThunk(USER_ACTION_TYPES.LOGIN_WITH_MOBILE, async (mobileNumber: string) => {
    try {
        const confirmationData = await firebase
            .auth()
            .signInWithPhoneNumber('+91' + mobileNumber, window.recaptchaVerifier);
        window.confirmationResult = confirmationData || null;
        return {
            mobileNumber
        };
    } catch (error) {
        throw error;
    }
});

export const verifyUserOTP = createAsyncThunk(
    USER_ACTION_TYPES.VERIFY_OTP,
    async ({ otp, enteredMobile }: { otp: string; enteredMobile: string }) => {
        try {
            if (window.confirmationResult) {
                // ask firebase confirmationResult to confirm the OTP
                const response = await window.confirmationResult.confirm(otp);
                // if response.user is present, otp authentication was successful
                if (response.user && enteredMobile.length) {
                    const result = await getUserData('mobile', enteredMobile);
                    if (result) {
                        return {
                            data: { ...result, loginMethod: 'mobile' }
                        };
                    } else {
                        FirebaseAuth.currentUser?.delete();
                        throw new Error('User not found in records');
                    }
                }
            } else {
                throw new Error('Something went wrong! Please refresh the page and try again.');
            }
        } catch (e: any) {
            if (e.code === 'auth/invalid-verification-code') {
                throw new Error('Phone number or OTP is incorrect.');
            }
            throw e;
        }
    }
);

export const loginWithOAuth = createAsyncThunk(
    USER_ACTION_TYPES.LOGIN_WITH_OAUTH,
    async ({ loginMethod }: { loginMethod: IOAuthType }) => {
        let provider;
        if (loginMethod === 'google') {
            provider = new FirebaseCaptcha.GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
        } else if (loginMethod === 'outlook') {
            provider = new FirebaseCaptcha.OAuthProvider('microsoft.com');
        }

        if (provider) {
            return await FirebaseAuth.signInWithPopup(provider).then(async (res) => {
                const currentUser = FirebaseAuth.currentUser;
                const email =
                    loginMethod === 'google'
                        ? (res?.additionalUserInfo?.profile as any).email || ''
                        : (res?.additionalUserInfo?.profile as any).userPrincipalName || '';
                if (email) {
                    try {
                        if (currentUser) {
                            return currentUser
                                .updateEmail(email)
                                .then(async () => {
                                    const result = await getUserData('email', email);
                                    if (result) {
                                        return {
                                            data: { ...result, loginMethod: 'email' }
                                        };
                                    } else {
                                        currentUser?.delete();
                                        throw new Error('User not found in records');
                                    }
                                })
                                .catch((e) => {
                                    throw new Error(e.message);
                                });
                        }
                    } catch (e) {
                        throw e;
                    }
                }
            });
        }
    }
);
type ILoginTracker = ({ name, properties }: { name: string; properties?: Record<string, unknown> | undefined }) => void;
export const checkUserAccess = createAsyncThunk(
    USER_ACTION_TYPES.CHECK_USER_ACCESS,
    async ({
        redirectFunction,
        roles,
        setIsLoading,
        trackLogin
    }: {
        redirectFunction: () => void;
        roles: string[];
        setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
        trackLogin: ILoginTracker;
    }) => {
        try {
            if (!roles.length) {
                throw new Error('User does not have any roles');
            }
            const permissions = await getRolePermissions(roles);
            if (!permissions.length) {
                throw new Error('User roles do not have any permissions');
            }
            if (!(process.env.REACT_APP_NAME || '').trim()) {
                throw new Error('Cannot find application name');
            }
            const appPermissionId = await getAppPermissionID(process.env.REACT_APP_NAME || '');
            if (!appPermissionId.trim()) {
                throw new Error('Cannot find application permission in IAM');
            }
            if (permissions.includes(appPermissionId)) {
                setIsLoading(false);
                trackLogin({
                    name: SEGMENT_ACTIONS.IDENTIFY.USER_LOGIN.name
                });
                redirectFunction();
                return {
                    isUserAuthenticated: true
                };
            } else {
                throw new Error('User does not have permission to access this application');
            }
        } catch (error) {
            throw error;
        }
    }
);
export const getUserDetails = createAsyncThunk(
    USER_ACTION_TYPES.FETCH_USER,
    async ({ key, value }: { key: string; value: string }) => {
        try {
            const result = await getUserData(key, value);
            if (result) {
                return {
                    data: { ...result, loginMethod: 'mobile' }
                };
            } else {
                throw new Error('Unable to fetch user details');
            }
        } catch (e) {
            throw e;
        }
    }
);
