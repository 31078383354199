import React, { Suspense } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { SPAContainer } from '.';
import { INTERNAL_ROUTES, MAIN_ROUTES } from '../../utils/constants/RouteConstants';
import { AuthRoute } from '../../utils/RouteGuards';
import { Loader, LoginPanel } from '../atoms';
import { TOKENS } from '../../utils/constants/Tokens';
import useSegment from '../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../utils/constants/SegmentActionConstants';
import { getSegmentPageName } from '../../utils/common';
const LoginContainer = React.lazy(() => import('./LoginContainer'));
const TopupLandingPage = React.lazy(() => import('./TopupLandingPage'));
const MainRouter: React.FunctionComponent = () => {
    const location = useLocation();
    const trackPage = useSegment('page');
    const history = useHistory();
    React.useEffect(() => {
        const currentPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
        trackPage({
            name: currentPageAction.name,
            properties: {
                page_url: (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                page_id: (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || ''
            }
        });
    }, [location.pathname]);
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <Route exact path={MAIN_ROUTES.topUpLanding}>
                    <TopupLandingPage />
                </Route>
                <Route exact path={MAIN_ROUTES.login}>
                    <LoginContainer
                        redirectTo={() => history.push(INTERNAL_ROUTES.dashboard)}
                        PanelComponent={LoginPanel}
                        title={'Get started'}
                        subTitle={'Login to view your policy details'}
                        authTokenName={TOKENS.FIREBASE_AUTH_TOKEN}
                    />
                </Route>

                <AuthRoute exact path={INTERNAL_ROUTES.dashboard} Component={SPAContainer} />
                <AuthRoute exact path={INTERNAL_ROUTES.summary} Component={SPAContainer} />
            </Switch>
        </Suspense>
    );
};

export default MainRouter;
