/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import {
    StyledModalContainer,
    StyledTitleContainer,
    StyledButtonContainer,
    StyledFeedbackWrapper,
    StyledDivIcon,
    StyledPDTable,
    SelectUL,
    SelectLI,
    SelectULColumn,
    StyledButton
} from './styles';
import { Modal } from '..';
import { IFeedbackModal } from './types';

import { TextView } from '../TextView';
import theme from '../../../theme';
import TopUpModal from '../../containers/TopUpModal';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
const MedicalModal: React.FunctionComponent<IFeedbackModal> = ({ isVisible, setIsVisible }) => {
    const [showTopUp, setShowTopUp] = React.useState(false);
    const trackClick = useSegment('click');
    return (
        <>
            <Modal setIsVisible={setIsVisible} isVisible={isVisible}>
                <StyledModalContainer>
                    <StyledTitleContainer>Medical Conditions</StyledTitleContainer>
                    <StyledFeedbackWrapper>
                        <StyledDivIcon />
                        <TextView
                            fontWeight='400'
                            margin={'0px 0px 0px 10px'}
                            fontSize='16px'
                            color={theme.colors.beyondGrey}
                        >
                            Any physical defect, deformity or disability
                        </TextView>
                    </StyledFeedbackWrapper>
                    <StyledFeedbackWrapper>
                        <StyledDivIcon />
                        <TextView
                            fontWeight='400'
                            margin={'0px 0px 0px 10px'}
                            fontSize='16px'
                            color={theme.colors.beyondGrey}
                        >
                            Currently hospitalised for any treatment/ailment/adverse medical condition
                        </TextView>
                    </StyledFeedbackWrapper>
                    <StyledFeedbackWrapper>
                        <StyledDivIcon />
                        <TextView
                            fontWeight='400'
                            margin={'0px 0px 0px 10px'}
                            fontSize='16px'
                            color={theme.colors.beyondGrey}
                        >
                            Recommended/undergone any kind of surgery including orthopaedics
                        </TextView>
                    </StyledFeedbackWrapper>
                    <StyledFeedbackWrapper>
                        <StyledDivIcon />
                        <TextView
                            fontWeight='400'
                            margin={'0px 0px 0px 10px'}
                            fontSize='16px'
                            color={theme.colors.beyondGrey}
                        >
                            Pregnant at the time of this application
                        </TextView>
                    </StyledFeedbackWrapper>
                    <StyledFeedbackWrapper>
                        <StyledDivIcon />
                        <TextView
                            fontWeight='400'
                            margin={'0px 0px 0px 10px'}
                            fontSize='16px'
                            color={theme.colors.beyondGrey}
                        >
                            Following Pre-Exiting Diseases (12) ↓
                        </TextView>
                    </StyledFeedbackWrapper>
                    <StyledPDTable>
                        <SelectUL>
                            <div>
                                <SelectLI>Cancer, Pre malignancy tendencies</SelectLI>
                                <SelectLI>Multiple sclerosis</SelectLI>
                                <SelectLI>Thalassemia Major</SelectLI>
                                <SelectLI>Chronic Liver diseased</SelectLI>
                                <SelectLI>Ulcreative colitis, {'Crohn\'s'} disease</SelectLI>
                            </div>
                            <div>
                                <SelectLI>Rheumatoid Arthritis, Autoimmune Diseases</SelectLI>
                                <SelectLI>History of Cerebrovascular disease (Stroke)</SelectLI>
                                <SelectLI>Chronic obstructive pulmonary disease (COPD)</SelectLI>
                                <SelectLI>Epilepsy</SelectLI>
                                <SelectLI>Gentic Disorder</SelectLI>
                            </div>
                        </SelectUL>

                        <SelectULColumn>
                            <SelectLI>
                                Renal Transplant/ Congenital disorders of Renal System, Renal failure /Chronic <br />{' '}
                                renal disorder/ ESRD (End stage renal disorder), Proteinuria
                            </SelectLI>
                            <SelectLI>
                                Congestive heart failure / Conduction Abnormalities of Cardiac System/ <br /> Pacemaker
                                implantation / Congenital Heart Disease / Other Heart ailments
                            </SelectLI>
                        </SelectULColumn>
                    </StyledPDTable>

                    <StyledButtonContainer>
                        <TextView fontSize={'16px'} color={theme.colors.loopEmerald}>
                            Curious why we need this declaration?
                        </TextView>

                        <StyledButton
                            onClick={() => {
                                trackClick({
                                    name: SEGMENT_ACTIONS.CLICK.TALK_TO_US.name,
                                    properties: {
                                        describe: 'User has clicked on a talk to us button in Medical condition modal'
                                    }
                                });
                                setShowTopUp(true);
                            }}
                        >
                            {'Talk to Us'}
                        </StyledButton>
                    </StyledButtonContainer>
                </StyledModalContainer>
                <TopUpModal visible={showTopUp} setVisible={setShowTopUp} />
            </Modal>
        </>
    );
};

export default MedicalModal;
