import React from 'react';
import {
    StyledContainer,
    StyledLeftContainerIcon,
    StyledLeftContainerSection,
    StyledSection,
    StyledText,
    StyledSubtext,
    StyledImageContainer
} from './styles';
import { loginShapeIcon, ClipboardIcon, HospitalIcon, QuotesImg } from '../../../assets/img';
import theme from '../../../theme';
import { ImageView } from '../ImageView';

const LoginPanel: React.FunctionComponent = () => {
    return (
        <StyledContainer>
            <StyledImageContainer>
                <StyledLeftContainerIcon src={loginShapeIcon} />
            </StyledImageContainer>

            <StyledLeftContainerSection>
                <StyledSection>
                    <ImageView
                        src={ClipboardIcon}
                        backgroundColor={theme.colors.planRed}
                        height='26px'
                        width='26px'
                        containerMinHeight='42px'
                        containerWidth='42px'
                        radius='21px'
                    />
                    <StyledText>
                        Pre-Existing Diseases
                        <StyledSubtext>Covered from Day 1*</StyledSubtext>
                    </StyledText>
                </StyledSection>

                <StyledSection>
                    <ImageView
                        src={HospitalIcon}
                        backgroundColor={theme.colors.coverYellow}
                        height='26px'
                        width='26px'
                        containerMinHeight='42px'
                        containerWidth='42px'
                        radius='21px'
                    />
                    <StyledText>
                        7,500+
                        <StyledSubtext>Network Hospitals</StyledSubtext>
                    </StyledText>
                </StyledSection>

                <StyledSection>
                    <ImageView
                        src={QuotesImg}
                        backgroundColor={theme.colors.teamTurquoise}
                        height='26px'
                        width='26px'
                        containerMinHeight='42px'
                        containerWidth='42px'
                        radius='21px'
                    />
                    <StyledText>
                        Pocket friendly premiums
                        <StyledSubtext>Starting at ₹3599/year</StyledSubtext>
                    </StyledText>
                </StyledSection>
            </StyledLeftContainerSection>
        </StyledContainer>
    );
};

export default LoginPanel;
