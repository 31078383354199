import { createSlice } from '@reduxjs/toolkit';
import { initialAdapterState } from '../../../components/pages/LoginContainer/redux/slices/UserSlice/initialState';
import { initialState } from './initialState';
import {
    fetchUserPolicyList,
    fetchPolicyDetails,
    fetchTopUpPolicyDetails,
    calculatePremium,
    fetchPolicyBenefitsDetails,
    createPaymentOrder,
    fetchOrderStatus,
    createFeedback,
    enrolUserPolicy
} from './thunks';

const policyListSlice = createSlice({
    name: 'policyList',
    initialState,
    reducers: {
        resetState(state) {
            state.policyList = initialState.policyList;
        },
        resetOrderState(state) {
            state.paymentOrder = initialState.paymentOrder;
        },
        resetCalculatedPremium(state) {
            state.memberTopUp = initialState.memberTopUp;
        },

        clearStates(state) {
            state.policyList = initialState.policyList;
            state.policyDetails = initialState.policyDetails;
            state.policyBenefits = initialState.policyBenefits;
            state.topupPolicyDetails = initialState.topupPolicyDetails;
            state.memberTopUp = initialState.memberTopUp;
        }
        // updateAnnualPremium(state, { payload }) {
        //     state.annualPremium = payload.data;
        // }
        // setSelectedMemberTopUp(state, { payload }) {
        //     state.selectedMemberTopUp = payload.selectedMemberTopUp;
        // }
    },
    extraReducers: {
        [fetchUserPolicyList.pending as any]: (state) => {
            state.policyList.loading = true;
            state.policyList.error = null;
            state.policyList.data = null;
        },
        [fetchUserPolicyList.rejected as any]: (state, { error }) => {
            state.policyList.error = error;
            state.policyList.data = null;
            state.policyList.loading = false;
        },
        [fetchUserPolicyList.fulfilled as any]: (state, { payload }) => {
            state.policyList.error = null;
            state.policyList.data = payload.policyList;
            state.policyList.loading = false;
        },

        [fetchPolicyDetails.pending as any]: (state) => {
            state.policyDetails.loading = true;
            state.policyDetails.error = null;
            state.policyDetails.data = null;
        },
        [fetchPolicyDetails.rejected as any]: (state, { error }) => {
            state.policyDetails.error = error;
            state.policyDetails.data = null;
            state.policyDetails.loading = false;
        },
        [fetchPolicyDetails.fulfilled as any]: (state, { payload }) => {
            state.policyDetails.error = null;
            state.policyDetails.data = payload.policyDetails;
            state.policyDetails.loading = false;
        },

        [fetchPolicyBenefitsDetails.pending as any]: (state) => {
            state.policyBenefits.loading = true;
            state.policyBenefits.error = null;
            state.policyBenefits.data = null;
        },
        [fetchPolicyBenefitsDetails.rejected as any]: (state, { error }) => {
            state.policyBenefits.error = error;
            state.policyBenefits.data = null;
            state.policyBenefits.loading = false;
        },
        [fetchPolicyBenefitsDetails.fulfilled as any]: (state, { payload }) => {
            state.policyBenefits.error = null;
            state.policyBenefits.data = payload.policyBenefits;
            state.policyBenefits.loading = false;
        },

        [fetchTopUpPolicyDetails.pending as any]: (state) => {
            state.topupPolicyDetails.loading = true;
            state.topupPolicyDetails.error = null;
            state.topupPolicyDetails.data = null;
        },
        [fetchTopUpPolicyDetails.rejected as any]: (state, { error }) => {
            state.topupPolicyDetails.error = error;
            state.topupPolicyDetails.data = null;
            state.topupPolicyDetails.loading = false;
        },
        [fetchTopUpPolicyDetails.fulfilled as any]: (state, { payload }) => {
            state.topupPolicyDetails.error = null;
            state.topupPolicyDetails.data = payload.policyDetails;
            state.topupPolicyDetails.loading = false;
        },

        [calculatePremium.pending as any]: (state) => {
            state.memberTopUp.loading = true;
            state.memberTopUp.error = null;
            state.memberTopUp.data = null;
        },
        [calculatePremium.rejected as any]: (state, { error }) => {
            state.memberTopUp.error = error;
            state.memberTopUp.data = null;
            state.memberTopUp.loading = false;
        },
        [calculatePremium.fulfilled as any]: (state, { payload }) => {
            state.memberTopUp.loading = false;
            state.memberTopUp.error = null;
            state.memberTopUp = payload.memberTopUp.data;
        },

        [createPaymentOrder.pending as any]: (state) => {
            state.paymentOrder.loading = true;
            state.paymentOrder.error = null;
            state.paymentOrder.data = null;
        },
        [createPaymentOrder.rejected as any]: (state, { error }) => {
            state.paymentOrder.error = error;
            state.paymentOrder.data = null;
            state.paymentOrder.loading = false;
        },
        [createPaymentOrder.fulfilled as any]: (state, { payload }) => {
            state.paymentOrder.loading = false;
            state.paymentOrder.error = null;
            state.paymentOrder = payload.paymentOrder;
        },

        [fetchOrderStatus.pending as any]: (state) => {
            state.paymentOrderStatus.loading = true;
            state.paymentOrderStatus.error = null;
            state.paymentOrderStatus.data = null;
        },
        [fetchOrderStatus.rejected as any]: (state, { error }) => {
            state.paymentOrderStatus.error = error;
            state.paymentOrderStatus.data = null;
            state.paymentOrderStatus.loading = false;
        },
        [fetchOrderStatus.fulfilled as any]: (state, { payload }) => {
            state.paymentOrderStatus.loading = false;
            state.paymentOrderStatus.error = null;
            state.paymentOrderStatus = payload.paymentOrderStatus;
        },

        [createFeedback.pending as any]: (state) => {
            state.feedbackDetails.loading = true;
            state.feedbackDetails.error = null;
            state.feedbackDetails.data = null;
        },
        [createFeedback.rejected as any]: (state, { error }) => {
            state.feedbackDetails.error = error;
            state.feedbackDetails.data = null;
            state.feedbackDetails.loading = false;
        },
        [createFeedback.fulfilled as any]: (state, { payload }) => {
            state.feedbackDetails.loading = false;
            state.feedbackDetails.error = null;
            state.feedbackDetails = payload.feedbackDetails;
        },

        [enrolUserPolicy.pending as any]: (state) => {
            state.enrolUser.loading = true;
            state.enrolUser.error = null;
            state.enrolUser.data = null;
        },
        [enrolUserPolicy.rejected as any]: (state, { error }) => {
            state.enrolUser.error = error;
            state.enrolUser.data = null;
            state.enrolUser.loading = false;
        },
        [enrolUserPolicy.fulfilled as any]: (state, { payload }) => {
            state.enrolUser.loading = false;
            state.enrolUser.error = null;
            state.enrolUser = payload.enrolUser.data;
        },
        'common/cleanup': (state) => {
            state.policyList = { ...initialAdapterState };
            state.memberTopUp.data = initialState.memberTopUp.data;
            state.memberTopUp.error = initialState.memberTopUp.error;
            state.memberTopUp.loading = initialState.memberTopUp.loading;
        }
    }
});
export { fetchUserPolicyList, fetchPolicyDetails, fetchTopUpPolicyDetails, calculatePremium };
export default policyListSlice.reducer;
export const { resetState, clearStates, resetOrderState, resetCalculatedPremium } = policyListSlice.actions;
