import styled from 'styled-components';
import theme from '../../../theme';

export const StyledContainer = styled.div(
    (p) => `
    display:flex;
    background-color: ${p.theme.colors.loopEmerald};
    // justify-content:center;
    align-items:center;
    flex-direction:column;
    @media (max-width: 768px) {
        padding:30px 20px;
    }
`
);

export const ErrorContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* padding: 90px 120px; */
    background-color: ${theme.colors.lightGrey};
    @media (max-width: 768px) {
        padding: 30px 20px;
        flex-direction: column;
    }
`;

export const WrapperContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 90px 100px;
    background-color: ${theme.colors.lightGrey};
    @media (max-width: 768px) {
        padding: 30px 20px;
        flex-direction: column;
    }
`;

export const StyledWrapperContainer = styled.div`
    display: flex;
    width: 84%;
    flex-direction: column;
    align-items: center;
    margin: 25px 0px 50px 0px;
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const StyledDetailsWrapper = styled.div`
    display: flex;
    /* min-width: 370px; */
    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
        margin-top: 20px;
    }
`;

export const StyledLoaderWrapper = styled.div`
    position: absolute;
    top: 15px;
    left: 50px;
`;

export const StyledHeadingContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
`;

export const StyledContainerWrapper = styled.div`
    width: 90%;
    display: flex;
    margin-top: 50px;
    flex-direction: row;
    @media (max-width: 768px) {
        padding: 0% 5%;
        margin-top: 0px;
        flex-direction: column;
    }
`;

export const StyledTopUpContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 30px;
`;

export const StyledDaysLeftContainer = styled.div`
    display: flex;
    border-radius: 0px 0px 16px 16px;
    background-color: ${(p) => p.theme.colors.darkTheme};
    padding: 15px 34px;
    align-items: center;
`;
export const StyledInternalTopContainer = styled.div<{
    $borderRadius: string;
}>`
    background-color: ${(p) => p.theme.colors.darkGreen};
    padding: 30px 34px;
    border-radius: ${(p) => p.$borderRadius || '16px'};
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;
export const StyledBPRightSection = styled.div`
    width: 30%;
    display: flex;
    justify-content: center;
    align-item: center;
`;

export const StyledBPContainer = styled.div`
    background-color: #035645;
    display: flex;
    justify-content: center;
    padding: 25px 0px;
    border-radius: 16px;
    margin-top: -5px;
`;

export const StyledWrapper = styled.div`
    width: 95%;
`;

export const StyledBPTitle = styled.span(
    (p) => `
    color: ${p.theme.colors.white};
    font-size:  ${p.theme.fontSizes.body};
    font-style: normal;
    font-weight: 400;
    margin-bottom:25px;
    display: flex;
    justify-content: space-between;
`
);
export const StyledNameContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: fled-start;
    margin-bottom: 45px;
`;
export const Container = styled.div`
    display: flex;
    flex-direction: row;
`;

export const StyledSAHeaderContainer = styled.div`
    display: flex;
    align-items: center;
`;
export const StyledSAHeaderTitle = styled.span`
    color: ${(p) => p.theme.colors.white};
    font-size: 14px;
    font-weight: 500;
`;
export const StyledInfoIcon = styled.img`
    width: 18px;
    height: 18px;
    margin-left: 8px;
    cursor: pointer;
`;

export const StyledSAContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 45px 0px;
`;

export const StyledInsuranceContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const StyledAPContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    @media (max-width: 768px) {
        align-items: center;
    }
`;

export const StyledSIContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-end;
    @media (max-width: 768px) {
        align-items: center;
    }
`;

export const StyledBadge = styled.span`
    display: flex;
    padding: 5px 8px;
    border-radius: 50px;
    text-align: center;
    color: ${(p) => p.theme.colors.loopEmerald};
    background: ${(p) => p.theme.colors.white};
    font-size: ${(p) => p.theme.fontSizes.body};
    display: flex;
    align-items: center;
`;

export const StyledAPContainerSpan = styled.span`
    font-size: 36px;
    color: ${(p) => p.theme.colors.coverYellow};
    line-height: 45px;
    position: relative;
`;

export const StyledDivider = styled.div`
    /* width: 10%; */
    display: flex;
    justify-content: center;
    margin: 0px 30px;
    @media (max-width: 768px) {
        display: none;
    }
`;

export const StyledDividerSpan = styled.span`
    width: 2px;
    height: 80px;
    background-color: #498a7d;
`;

export const StyledPaddingContainer = styled.div`
    width: 100%;
    padding-bottom: 84px;
`;

//

export const SIDetailsWrapper = styled.div`
    display: flex;
    min-width: 220px;
    justify-content: flex-end;
    @media (max-width: 768px) {
        justify-content: center;
        margin-bottom: 20px;
    }
`;

export const StyledSup = styled.sup`
    font-size: ${(p) => p.theme.fontSizes.heading};
    top: 7px;
    position: relative;
    margin-right: 3px;
`;

export const StyledSub = styled.sub`
    font-size: ${(p) => p.theme.fontSizes.heading};
    top: -7px;
    position: relative;
    margin-left: 2px;
`;
