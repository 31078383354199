import React from 'react';
import {
    StyledContainer,
    StyledHeading,
    StyledImage,
    StyledOtpContainer,
    StyledTitle,
    StyledUnderlinedText,
    StyledOtpHeading,
    StyledImageContainer
} from './styles';
import OtpInput from 'react-otp-input';
import { ILoginVerify } from './types';
import { backIcon } from '../../../../../../assets/img';
import { Loader } from '../../atoms';

const LoginVerify: React.FunctionComponent<ILoginVerify> = ({
    showMobileForm,
    email = '',
    phoneNumber = '',
    getOtp,
    goBack
}) => {
    const [otp, setOtp] = React.useState('');
    return (
        <StyledContainer>
            {!showMobileForm && (
                <>
                    <StyledTitle>Verify email</StyledTitle>
                    <StyledHeading>
                        We’ve shared a verification link on &nbsp;<b>{email}</b> <br />
                        Please click on the verification link provided in the email
                    </StyledHeading>
                </>
            )}
            {showMobileForm && phoneNumber ? (
                <>
                    <StyledHeading>
                        <StyledImageContainer>
                            <StyledImage onClick={goBack} src={backIcon} alt='Edit Icon' />
                        </StyledImageContainer>
                        An OTP has been sent to &nbsp;
                        <StyledUnderlinedText>{phoneNumber}</StyledUnderlinedText>
                    </StyledHeading>
                    <StyledOtpContainer>
                        <StyledOtpHeading>Enter OTP Here </StyledOtpHeading>
                        <OtpInput
                            value={otp}
                            onChange={(otpV: string) => {
                                setOtp(otpV);
                                getOtp && getOtp(otpV);
                            }}
                            numInputs={6}
                            inputStyle='otp-input'
                            containerStyle='otp-container'
                            isInputSecure={true}
                            shouldAutoFocus={true}
                        />
                    </StyledOtpContainer>
                </>
            ) : (
                <StyledOtpContainer>
                    <Loader />
                </StyledOtpContainer>
            )}
        </StyledContainer>
    );
};

export default LoginVerify;
