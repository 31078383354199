/* eslint-disable max-len */
export const REDIRECT_URL = {
    LinkedIn: 'https://www.linkedin.com/company/loop-health/',
    Instagram: 'https://www.instagram.com/loophealthindia/',
    Twitter: 'https://twitter.com/loophealthHQ',
    Facebook: 'https://www.facebook.com/loophealthindia/',
    Youtube: 'https://www.youtube.com/channel/UCPUc_YWsi_oj5oYjt0yjAZg',
    APP_STORE: 'https://apps.apple.com/in/app/loop-health/id1495161232',
    PLAY_STORE: 'https://play.google.com/store/apps/details?id=com.invoqhealth.loophealth.patient',
    TERMS_CONDITIONS:
        'https://storage.googleapis.com/loophealthpatient.appspot.com/Insurance/ICICI%20Adhoc%20Document%20T%26C%201.pdf'
};
