import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../redux';
import InternalRouter from './router';
import { fetchUserPolicyList } from '../../../redux/slices/PolicyListSlice';
// import { fetchTopUpPolicyDetails } from '../../../redux/slices/PolicyListSlice/thunks';
export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
    max-height: 100vh;
    width: 100vw;
    max-width: 100vw;
    background-color: ${(p) => p.theme.colors.white};
`;

const SPAContainer: React.FunctionComponent = () => {
    const userId = useSelector((state: ReduxState) => state.TopUp_user.userData.data?.userId);
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (userId) {
            dispatch(fetchUserPolicyList({ userId }));
            // const policyId = `${process.env.REACT_APP_TOPUP_POLICY_ID}`;
            // dispatch(fetchTopUpPolicyDetails({ policyId }));
        }
    }, [userId]);

    return (
        <StyledContainer>
            <InternalRouter />
        </StyledContainer>
    );
};

export default SPAContainer;
