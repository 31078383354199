import React from 'react';
import { useDispatch } from 'react-redux';
import { StyledErrorText, StyledTopMargin, StyledPhoneNumContainer, StyledStaticInput, StyledHeading } from './styles';
import { FirebaseCaptcha } from '../../../../../../adapters/provider';
import useFormInput from '../../../../../../utils/hooks/useFormInput';
import { Button, Input, Loader } from '../../atoms';
import { ILoginForm } from './types';
import { clearUserError } from '../../../redux/slices/UserSlice';

const LoginForm: React.FunctionComponent<ILoginForm> = ({
    showMobile,
    onSubmit,
    submitButtonId = '',
    loading = false,
    error = ''
}) => {
    const input = useFormInput('');
    const dispatch = useDispatch();
    React.useEffect(() => {
        if (submitButtonId === 'otp-button') {
            window.recaptchaVerifier = new FirebaseCaptcha.RecaptchaVerifier('otp-button', {
                size: 'invisible'
            });
        }
    }, [FirebaseCaptcha.RecaptchaVerifier, submitButtonId]);
    // TODO: below code to be used for Validation Email
    // const validateEmail = (emailTxt: string) => {
    //     dispatch(clearUserError());
    //     if (!emailTxt) {
    //         return null;
    //     }
    //     const regEx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    //     return regEx.test(emailTxt);
    // };

    const validateMobile = (phoneTxt: string) => {
        dispatch(clearUserError());
        if (!phoneTxt) {
            return null;
        }
        const regEx = /^[6789]\d{9}$/;
        return regEx.test(phoneTxt);
    };

    React.useEffect(() => {
        if (input.value.length === 10 && validateMobile(input.value)) {
            document.addEventListener('keypress', handleEnterKey);
            return () => {
                document.removeEventListener('keypress', handleEnterKey);
            };
        }
    }, [input]);
    const handleEnterKey = (e: any): void => {
        if (input.value.length === 10 && e.keyCode === 13) {
            onSubmit(input.value);
        }
    };

    return (
        <>
            <StyledHeading $textAlign={'center'}>Your contact number registered with Loop</StyledHeading>
            <StyledPhoneNumContainer>
                <StyledStaticInput disabled value='+91' />
                <Input
                    placeholder={showMobile ? 'Mobile Number' : 'Company Email'}
                    {...input}
                    name={showMobile ? 'phone' : 'email'}
                    maxLen={showMobile ? 10 : undefined}
                    type={showMobile ? 'number' : 'email'}
                    showIcon={input.value.length === 10 ? (validateMobile(input.value) ? true : false) : false}
                />
            </StyledPhoneNumContainer>

            {!!error.length && <StyledErrorText>{error}</StyledErrorText>}
            <StyledTopMargin>
                <Button
                    id={submitButtonId}
                    type='Otp'
                    disabled={
                        showMobile
                            ? input.value.length === 10
                                ? validateMobile(input.value)
                                    ? false
                                    : true
                                : true
                            : !validateMobile(input.value)
                    }
                    onClick={() => !loading && onSubmit(input.value)}
                    fullWidth={true}
                >
                    {loading ? <Loader inverted /> : showMobile ? 'Get OTP' : 'Verify'}
                </Button>
            </StyledTopMargin>
        </>
    );
};

export default LoginForm;
