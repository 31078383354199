import React from 'react';
import {
    StyledLogo,
    StyledContainer,
    StyledContainerLeft,
    StyledContainerRight,
    StyledSocialWrapper,
    StyledAddressText,
    StyledSocialIcons,
    StyledPoweredWrapper
} from './styles';
import {
    InstagramIcon,
    FacebookIcon,
    LinkedInIcon,
    YoutubeIcon,
    TwitterIcon,
    LoopIconGreen,
    iciciIcon
} from '../../../assets/img';
import { redirect } from '../../../utils/common';
import { REDIRECT_URL } from '../../../utils/constants/RedirectConstants';
import { TextView } from '../../atoms/TextView';
import theme from '../../../theme';

const Footer: React.FunctionComponent = ({}) => {
    const redirectToPages = (url: string) => {
        redirect(url);
    };
    return (
        <StyledContainer>
            <StyledContainerLeft>
                <StyledLogo src={LoopIconGreen} alt={'Loop Health Logo'} />
                <TextView fontSize='24px' margin='0px 0px 10px 0px' fontWeight={'400'} color={theme.colors.white}>
                    020-7117-3585
                </TextView>
                <TextView fontSize='18px' margin='0px 0px 0px 0px' fontWeight={'100'} color={theme.colors.white}>
                    (Monday - Saturday 09:00 - 21:00 IST)
                </TextView>
                <StyledPoweredWrapper>
                    <TextView fontSize='16px' margin='25px 10px 20px 0px' color={theme.colors.white}>
                        Super Top Ups Powered by
                    </TextView>
                    <img src={iciciIcon} />
                </StyledPoweredWrapper>
                <TextView fontSize='14px' margin='10px 0px 0px 0px' fontWeight={'100'} color={theme.colors.white}>
                    © LOOP 2022
                </TextView>
            </StyledContainerLeft>
            <StyledContainerRight>
                <StyledAddressText>
                    <TextView fontSize='18px' margin='0px 0px 0px 0px' fontWeight={'100'} color={theme.colors.white}>
                        Registered Loop office: House #24-2, Swapnamandir Society Om Bldg Lane No.2, Erandwane, CDSS
                        Karve Road, Pune, MH 411004 India
                    </TextView>
                </StyledAddressText>
                <StyledSocialWrapper>
                    <StyledSocialIcons onClick={() => redirectToPages(REDIRECT_URL.LinkedIn)}>
                        <img src={LinkedInIcon} />
                    </StyledSocialIcons>
                    <StyledSocialIcons onClick={() => redirectToPages(REDIRECT_URL.Instagram)}>
                        <img src={InstagramIcon} />
                    </StyledSocialIcons>
                    <StyledSocialIcons onClick={() => redirectToPages(REDIRECT_URL.Twitter)}>
                        <img src={TwitterIcon} />
                    </StyledSocialIcons>
                    <StyledSocialIcons onClick={() => redirectToPages(REDIRECT_URL.Facebook)}>
                        <img src={FacebookIcon} />
                    </StyledSocialIcons>
                    <StyledSocialIcons onClick={() => redirectToPages(REDIRECT_URL.Youtube)}>
                        <img src={YoutubeIcon} />
                    </StyledSocialIcons>
                </StyledSocialWrapper>
            </StyledContainerRight>
        </StyledContainer>
    );
};

export default Footer;
