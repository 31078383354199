import React from 'react';
import { StyledContainerUNAvailable, StyledContainerAvailable, StyledLoaderContainer } from './style';

import TopUpUnAvailable from '../../../../containers/TopUpUnAvailable';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../../redux';
import { Loader } from '../../../../atoms';
import { ageFromDateOfBirthday, getDaysLeft } from '../../../../../utils/common';
import TopUpAvailable from '../../../../containers/TopUpAvailable';
import { fetchPolicyBenefitsDetails } from '../../../../../redux/slices/PolicyListSlice/thunks';
import { Header } from '../../../../containers';
import { LoopGreenLogo } from '../../../../../assets/img';
import TopupSummary from '../../../../containers/TopupSummary';
import { fetchTopUpPolicyDetails } from '../../../../../redux/slices/PolicyListSlice';
import { TextView } from '../../../../atoms/TextView';
import theme from '../../../../../theme';
const DashboardPage: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state: ReduxState) => state.TopUp_policyData.policyList.loading);
    const topUpPolicyDetailsLoading = useSelector((state: ReduxState) =>
        state.TopUp_policyData.topupPolicyDetails.loading);
    const error = useSelector((state: ReduxState) => state.TopUp_policyData.policyList.error);
    const policyData: any = useSelector((state: ReduxState) => state.TopUp_policyData.policyList.data);
    const basePolicyData = policyData?.baseGmcPolicyData;
    const topPolicyData = policyData?.topUpPolicyData;
    const policyId = `${process.env.REACT_APP_TOPUP_POLICY_ID}`;

    React.useEffect(() => {
        if (policyId) {
            dispatch(fetchTopUpPolicyDetails({ policyId }));
        }
    }, [policyId]);
    const topUpPolicyDetails = useSelector((state: ReduxState) => state.TopUp_policyData.topupPolicyDetails.data);
    const topUpAvailable = getDaysLeft(
        basePolicyData?.policyStartDate,
        'startDate',
        topUpPolicyDetails?.enrolmentThreshold || 30
    );
    const policyActive = getDaysLeft(basePolicyData?.policyEndDate, 'endDate');
    const userDob = useSelector((state: ReduxState) => state.TopUp_user.userData.data?.dob);
    React.useEffect(() => {
        if (basePolicyData?.policyId && !isLoading) {
            dispatch(
                fetchPolicyBenefitsDetails({ policyId: basePolicyData?.policyId, slabId: basePolicyData?.slabId })
            );
        }
    }, [basePolicyData]);

    return (
        <>
            {isLoading || topUpPolicyDetailsLoading ? (
                <StyledLoaderContainer>
                    <Loader />
                    <TextView
                        textAlign={'center'}
                        fontSize={'20px'}
                        margin={'30px 0px 0px 0px'}
                        color={theme.colors.beyondGrey}
                    >
                        Please wait while we fetch your <br /> policy details..
                    </TextView>
                </StyledLoaderContainer>
            ) : error?.message.length ? (
                <>
                    <Header bgColor={false} logo={LoopGreenLogo} />
                    <StyledContainerUNAvailable>
                        <TopUpUnAvailable
                            isTopUpAvailable={topUpAvailable || false}
                            isPolicyActive={policyActive || false}
                            isPolicyAvailable={false}
                            userAge={ageFromDateOfBirthday(userDob) > 80 ? true : false}
                        />
                    </StyledContainerUNAvailable>
                </>
            ) : (
                <>
                    {topPolicyData && Object.keys(topPolicyData).length > 0 ? (
                        <TopupSummary />
                    ) : (
                        <>
                            {!isLoading &&
                            policyData &&
                            topUpAvailable &&
                            policyActive &&
                            ageFromDateOfBirthday(userDob) <= 80 ? (
                                    <StyledContainerAvailable>
                                        <TopUpAvailable policyData={basePolicyData} />
                                    </StyledContainerAvailable>
                                ) : (
                                    <>
                                        {basePolicyData && (
                                            <>
                                                <Header bgColor={false} logo={LoopGreenLogo} />
                                                <StyledContainerUNAvailable>
                                                    <TopUpUnAvailable
                                                        isTopUpAvailable={topUpAvailable || false}
                                                        isPolicyActive={policyActive || false}
                                                        isPolicyAvailable={basePolicyData?.isTopUpPolicy ? false : true}
                                                        userAge={ageFromDateOfBirthday(userDob) > 80 ? false : true}
                                                    />
                                                </StyledContainerUNAvailable>
                                            </>
                                        )}
                                    </>
                                )}
                        </>
                    )}
                </>
            )}
        </>
    );
};
export default DashboardPage;
