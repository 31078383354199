import React from 'react';
import { StyledContainer, Spacing } from './styles';
import { GoogleIcon, OutlookIcon } from '../../../assets/img';
import { Button } from '../../atoms';
import { ILoginOAuth } from './types';

const LoginOAuth: React.FunctionComponent<ILoginOAuth> = ({ signInWithOAuth }) => {
    return (
        <StyledContainer>
            <Button type="OAuth" iconSrc={GoogleIcon} fullWidth onClick={() => signInWithOAuth('google')}>
                Continue with Gmail
            </Button>
            <Spacing />
            <Button type="OAuth" iconSrc={OutlookIcon} fullWidth onClick={() => signInWithOAuth('outlook')}>
                Continue with Outlook
            </Button>
        </StyledContainer>
    );
};

export default LoginOAuth;
