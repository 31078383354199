import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearUserError,
    loginWithMobile,
    loginWithOAuth
} from '../../../redux/slices/UserSlice';
import { IOAuthType } from '../../../redux/types';
import { LoginForm, LoginOAuth } from '../../containers';
import {
    StyledContent,
    StyledDivider,
    StyledDividerText,
    StyledMobileFormContainer,
    StyledOAuthContainer
} from './styles';
import { ILoginPage } from './types';
import UnRegisteredUsers from '../../containers/UnRegisteredUsers';
const LoginPage: React.FunctionComponent<ILoginPage> = () => {
    const [isVisible, setIsVisible] = React.useState<boolean>(false);
    const dispatch = useDispatch();
    const userData = useSelector((state: any) => state.TopUp_user.userData);
    const userMobile = useSelector((state: any) => state.TopUp_user.userMobile);
    const loginWithMobileNo = (mobileNumber: string) => {
        dispatch(clearUserError());
        dispatch(loginWithMobile(mobileNumber));
    };
    const oAuthLogin = (type: IOAuthType) => {
        dispatch(clearUserError());
        dispatch(loginWithOAuth({ loginMethod: type }));
    };

    React.useEffect(() => {
        if (userData.error) {
            if (userData.error.message === 'User not found in records') {
                setIsVisible(true);
            }
        }
    }, [userData]);
    return (
        <StyledContent>
            <StyledMobileFormContainer>
                <LoginForm
                    loading={userMobile.loading}
                    showMobile={true}
                    onSubmit={loginWithMobileNo}
                    submitButtonId={'otp-button'}
                />
            </StyledMobileFormContainer>
            <StyledDivider>
                <StyledDividerText>Or</StyledDividerText>
            </StyledDivider>
            <StyledOAuthContainer>
                {userData.error && (
                    <UnRegisteredUsers
                        isVisible={isVisible}
                        setIsVisible={setIsVisible}
                    />
                )}
                <LoginOAuth signInWithOAuth={oAuthLogin} />
            </StyledOAuthContainer>
        </StyledContent>
    );
};

export default LoginPage;
