import React, { useEffect } from 'react';
import { Modal } from '../../atoms';
import { Button, Input } from '../../pages/LoginContainer/components/atoms';
import { TextView } from '../../atoms/TextView';
import { ITopUpModal } from './types';
import theme from '../../../theme';
import { StyledHeading, StyledStaticInput } from '../../pages/LoginContainer/components/containers/LoginForm/styles';
import { StyledPhoneNumContainer } from '../../pages/LoginContainer/styles';
import useFormInput from '../../../utils/hooks/useFormInput';
import { validateEmail, validateMobile } from '../../../utils/common';
import { StyledErrorText } from '../../pages/LoginContainer/components/pages/OTPPage/styles';
import {
    ButtonContainer,
    Container,
    StyledModalContainer,
    StyledContentImage,
    StyledButtonContainer,
    UserRegisteredButton
} from './styles';
import { CircularCloseIcon, ThankyouIcon } from '../../../assets/img';
import { useDispatch, useSelector } from 'react-redux';
import { postTopUpUserData } from '../../../redux/slices/TopUpLandingSlice/thunk';
import { selectTopUpUserData } from '../../../redux/slices/TopUpLandingSlice';
import useSegment from '../../../utils/hooks/useSegment';
import { useToast } from '../../../utils/hooks/useToast';

const TopUpModal: React.FunctionComponent<ITopUpModal> = ({ visible, setVisible }) => {
    const trackClick = useSegment('click');
    const dispatch = useDispatch();
    const mobileInput = useFormInput('');
    const emailInput = useFormInput('');
    const toast = useToast();
    const error = '';
    const topUpUserData = useSelector(selectTopUpUserData);
    const [showSuccessIsVisible, setShowSuccessIsVisible] = React.useState<boolean>(false);
    useEffect(() => {
        if (visible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'scroll';
        }
    }, [visible]);

    useEffect(() => {
        let toastId;
        if (topUpUserData.error && topUpUserData.loading === false) {
            toastId = toast?.displayToast('error', 'Failed', 'Error ');
            toast?.removeToast(toastId || '');
            setShowSuccessIsVisible(false);
        } else if (topUpUserData.data && topUpUserData.loading === false) {
            setShowSuccessIsVisible(true);
        }
    }, [topUpUserData]);

    const onGetCallBack = () => {
        // TODO: Handle get back call back
        const mobile = mobileInput.value;
        const email = emailInput.value;

        trackClick({
            name: 'GET A CALL BACK',
            properties: {
                mobile: mobile,
                email_entered: email
            }
        });
        dispatch(
            postTopUpUserData({
                email,
                mobile,
                event: 'explore_topup'
            })
        );
    };
    const onBtnClick = () => {
        mobileInput.setValue('');
        emailInput.setValue('');
        setShowSuccessIsVisible(false);
        setVisible(false);
    };
    return (
        <>
            <Modal
                isVisible={visible}
                CloseImg={CircularCloseIcon}
                headingColor={theme.colors.loopEmerald}
                title={!showSuccessIsVisible ? 'Let’s figure this out together!' : ''}
                setIsVisible={() => setVisible(!visible)}
                borderRadius='20px'
                borderBottom='0px'
            >
                {!showSuccessIsVisible ? (
                    <>
                        <TextView fontSize='17px' color={theme.colors.loopEmerald}>
                            Our team will contact you within 48 hours <br /> to helpyou purchase a Super Top-up
                        </TextView>
                        <Container $margin='42px 0px 0px 0px'>
                            <StyledHeading>Your Email</StyledHeading>
                            <Input
                                placeholder={'Email address'}
                                {...emailInput}
                                name={'email'}
                                type={'email'}
                                showIcon={
                                    emailInput.value.length > 4
                                        ? validateEmail(emailInput.value)
                                            ? true
                                            : false
                                        : false
                                }
                            />
                            <TextView
                                fontSize={'14px'}
                                textAlign={'end'}
                                color={theme.colors.error}
                                margin={'12px 0px 0px 0px'}
                            >
                                {emailInput.value.length > 7
                                    ? validateEmail(emailInput.value)
                                        ? ''
                                        : 'Enter Valid Email'
                                    : null}
                            </TextView>
                        </Container>
                        <Container $margin='12px 0px 0px 0px'>
                            <StyledHeading>Your Contact Number</StyledHeading>
                            <StyledPhoneNumContainer $padding='0px'>
                                <StyledStaticInput disabled value='+91' />
                                <Input
                                    placeholder={'Mobile Number'}
                                    {...mobileInput}
                                    name={'phone'}
                                    maxLen={10}
                                    type={'number'}
                                    showIcon={
                                        mobileInput.value.length === 10
                                            ? validateMobile(mobileInput.value)
                                                ? true
                                                : false
                                            : false
                                    }
                                />
                            </StyledPhoneNumContainer>
                            <TextView
                                fontSize={'14px'}
                                textAlign={'end'}
                                color={theme.colors.error}
                                margin={'12px 0px 0px 0px'}
                            >
                                {mobileInput.value.length > 9
                                    ? validateMobile(mobileInput.value)
                                        ? ''
                                        : 'Enter Valid Phone'
                                    : null}
                            </TextView>
                            {!!error.length && <StyledErrorText>{error}</StyledErrorText>}
                        </Container>
                        <ButtonContainer>
                            <Button
                                type='secondary'
                                fullWidth={false}
                                disabled={
                                    topUpUserData.loading === true ||
                                    !validateEmail(emailInput.value) ||
                                    !validateMobile(mobileInput.value)
                                }
                                color={theme.colors.coverUpGreen}
                                onClick={onGetCallBack}
                                borderRadius='16px'
                                fontColor={theme.colors.loopEmerald}
                            >
                                {topUpUserData.loading === true ? 'Please wait...' : 'Get a Call Back'}
                            </Button>
                        </ButtonContainer>
                    </>
                ) : (
                    <StyledModalContainer>
                        <StyledContentImage src={ThankyouIcon} />
                        <TextView margin='28px 0px 20px 0px' fontSize='20px' color={theme.colors.loopEmerald}>
                            Awesome! Our team will contact you <br /> within 48 hours to assist you :)
                        </TextView>
                        <StyledButtonContainer>
                            <UserRegisteredButton onClick={onBtnClick}>Got it!</UserRegisteredButton>
                        </StyledButtonContainer>
                    </StyledModalContainer>
                )}
            </Modal>
        </>
    );
};

export default TopUpModal;
