import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    /* min-width: 500px; */
    max-width: 100%;
    @media (max-width: 550px) {
        min-width: 100%;
    }
    .otp-input {
        border-radius: 10px;
        border: 0;
        background-color: #f1f3f5;
        margin: 1px 5px;
        justify-content: space-between;
        height: 56px;
        width: 56px !important;
    }
    .otp-input:focus {
        border: 1px solid ${(p) => p.theme.colors.loopEmerald};
    }

    @media (max-width: 550px) {
        .otp-input {
            height: 40px;
            width: 40px !important;
        }
    }

    .otp-container {
        justify-content: space-between;
    }
`;
export const StyledTitle = styled.h2(
    (p) => `
    font-size: ${p.theme.fontSizes.title};
    color: ${p.theme.colors.black};
    margin: 0;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
);
export const StyledHeading = styled.p(
    (p) => `
    font-size: ${p.theme.fontSizes.heading};
    color: ${p.theme.colors.loopEmerald};
    margin: 0px;
    font-weight: 300;
    line-height: 26px;
    width:100%;
    text-align:center;
`
);
export const StyledOtpContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    min-height: 75px;
    margin-top: 42px;
    margin-bottom: 10px;
    min-width: 420px;
    @media (max-width: 550px) {
        margin: 20px 0px;
        min-height: 0;
    }
`;
export const StyledImage = styled.img`
    width: 7px;
    cursor: pointer;
`;

export const StyledImageContainer = styled.p(
    (p) => `
    background-color: ${p.theme.colors.mutedBG};
    width:42px;
    height:42px;
    position:absolute;
    top:8%;
    left:80px;
    border-radius:50%;
    display:flex;
    align-item:center;
    justify-content:center;
    cursor:pointer;
    @media (max-width: 550px) {
        top:1%;
        left:10px;
    }
`
);

export const StyledUnderlinedText = styled.span`
    text-decoration: underline;
`;

export const StyledOtpHeading = styled.span(
    (p) => `
    font-size: ${p.theme.fontSizes.body};
    color: ${p.theme.colors.loopEmerald};
    margin: 0px;
    font-weight: 500;
    line-height: 16px;
    width:100%;
    text-align:center;
    margin-bottom:12px;
`
);
