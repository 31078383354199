import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistStore } from 'redux-persist';
import rootReducer from '../reducers';
import persistReducer from 'redux-persist/es/persistReducer';
// import logger from 'redux-logger';

const initialState = {};
const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['user', 'policyList'],
    version: 0.0
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [];
if (process.env.NODE_ENV === 'development') {
    // middlewares.push(logger);
}
middlewares.push(thunk);

const store = createStore(persistedReducer, initialState, compose(applyMiddleware(...middlewares)));

export default store;
export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
