import React from 'react';
import { createPortal } from 'react-dom';
import {
    StyledModalOverlay,
    StyledAbsoluteCloseIcon,
    StyledCloseIcon,
    StyledModalContainer,
    StyledModalContent,
    StyledTitle,
    StyledTitleContainer
} from './styles';
import { CloseIcon } from '../../../assets/img';
import { IModal } from './types';

const Modal: React.FunctionComponent<IModal> = ({
    children,
    isVisible,
    setIsVisible,
    title = '',
    CloseImg = null,
    headingColor,
    borderRadius,
    borderBottom,
    paddingTitle
}) => {
    const modalRef = React.useRef<HTMLDivElement | null>(null);
    React.useEffect(() => {
        if (modalRef.current) {
            // create a root node
            const rootElem = document.createElement('div');
            rootElem.setAttribute('id', 'modal-root-loop-health');
            // inserts rootElem as last element of body
            if (document.body.lastElementChild) {
                document.body.insertBefore(
                    rootElem,
                    document.body.lastElementChild.nextElementSibling
                );
            }
            // adds the modal reference to the root
            rootElem.appendChild(modalRef.current);
        }
    }, [modalRef.current]);
    React.useEffect(() => {
        if (!modalRef.current) {
            modalRef.current = document.createElement('div');
        }
    }, []);
    // if the refs have been initialised properly,
    // then port the contents of this component, onto the newly created element 'modal-root-loop-health'
    return isVisible && modalRef.current ? (
        createPortal(
            <StyledModalOverlay onClick={() => setIsVisible(false)}>
                <StyledModalContainer
                    $borderRadius={borderRadius}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                    }}
                >
                    {title.length > 0 && (
                        <StyledTitleContainer
                            $padding={paddingTitle}
                            $borderBottom={borderBottom}
                        >
                            <StyledTitle $color={headingColor}>
                                {title}
                            </StyledTitle>
                            <StyledCloseIcon
                                onClick={() => setIsVisible(false)}
                                src={CloseImg ? CloseImg : CloseIcon}
                                alt='close add member modal'
                            />
                        </StyledTitleContainer>
                    )}
                    {title.length === 0 && (
                        <StyledAbsoluteCloseIcon
                            onClick={() => setIsVisible(false)}
                            src={CloseIcon}
                            alt='close add member modal'
                        />
                    )}
                    <StyledModalContent>{children}</StyledModalContent>
                </StyledModalContainer>
            </StyledModalOverlay>,
            modalRef.current
        )
    ) : (
        <></>
    );
};

export default Modal;
