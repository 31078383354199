import { USER_ID_IDENTIFIER } from '../utils/constants';
import { FirebaseAuth, Firestore } from './provider';

const db = Firestore;

export const sendEmailVerificationLink = async (
    email: string,
    setLoading: (isLoading: boolean) => void,
    setVerificatonSent: (isSent: boolean) => void,
    docId: string
): Promise<any> => {
    const actionCodeSettings = {
        url: process.env.REACT_APP_REDIRECT_URL + `${USER_ID_IDENTIFIER}${docId}${USER_ID_IDENTIFIER}`,
        handleCodeInApp: true
    };
    return await FirebaseAuth.sendSignInLinkToEmail(email, actionCodeSettings)
        .then(() => {
            setLoading(false);
            setVerificatonSent(true);
        })
        .catch((error) => {
            setLoading(false);
            throw error;
        });
};

export const updateUserDataAPI = async (userId: string, key: string, value: string): Promise<any> => {
    try {
        return await db
            .collection('user')
            .doc(userId)
            .update({
                [key]: value
            });
    } catch (e) {
        console.log(e);
        throw e;
    }
};

export const getRolePermissions = async (roles: string[]): Promise<string[]> => {
    try {
        const permissions: string[] = [];
        const dbRoles: any = [];
        const query = await db.collection('roles').get();
        query.docs.map((doc) => {
            dbRoles.push(doc.data());
        });
        roles.map((role) => {
            const permission = dbRoles.filter((r: any) => r.id === role)[0].permissions;
            permissions.push(...permission);
        });
        return permissions;
    } catch (e) {
        throw e;
    }
};

export const getAppPermissionID = async (value: string): Promise<string> => {
    try {
        const query = await db.collection('IAM').where('value', '==', value).get();
        return query.docs[0].data().id;
    } catch (e) {
        throw e;
    }
};

export const getUserData = async (key: string, value: string): Promise<Record<string, unknown> | null> => {
    const data: any[] = [];
    try {
        // NOTE: Method type like how the user going to login e.g. using phone or email.
        // const res = await db.collection('user').where('active', '==', true).where(key, '==', value).get();
        const res = await db
            .collection('user')
            .where('active', '==', true)
            .where(key, '==', value)
            .where('patientType', '==', 'membership')
            .get();
        if (res.docs.length) {
            res.forEach((doc) => {
                data.push({ id: doc.id, ...doc.data() });
            });
            return (data && data.length && data[0]) || null;
        }
        return null;
    } catch (error) {
        throw error;
    }
};
