import React from 'react';
import {
    StyledModalContainer,
    StyledTitleContainer,
    StyledContentText,
    StyledButtonContainer,
    UserRegisteredButton,
    StyledFeedbackWrapper,
    StyledInputWrapper
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Input } from '../../atoms';
import { IFeedbackModal } from './types';
import useFormInput from '../../../utils/hooks/useFormInput';
import FeedbackStarRatings from '../FeedbackStarRatings';
import FeedbackSuccessModal from '../FeedbackSuccessModal';
import { createFeedback } from '../../../redux/slices/PolicyListSlice/thunks';
import { ReduxState } from '../../../redux';
import { Loader } from '../../atoms';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
const FeedbackModal: React.FunctionComponent<IFeedbackModal> = ({ isVisible, setIsVisible }) => {
    const [rating, setRating] = React.useState(0);
    const dispatch = useDispatch();
    const feedbackInput = useFormInput('');
    const userId = useSelector((state: ReduxState) => state.TopUp_user.userData.data?.userId);
    const feedbackDetails = useSelector((state: ReduxState) => state.TopUp_policyData.feedbackDetails);
    const trackClick = useSegment('click');
    const handleFeedbackSubmit = () => {
        trackClick(SEGMENT_ACTIONS.CLICK.FEEDBACK_SUBMITTED);
        dispatch(
            createFeedback({
                feedbackText: feedbackInput.value,
                taskRating: rating,
                patientId: userId
            })
        );
    };

    React.useEffect(() => {
        if (feedbackDetails.data) {
            setIsVisible(false);
            setFeedbackSuccessIsVisible(true);
        }
    }, [feedbackDetails]);

    const handleStarClicked = (val: any) => {
        setRating(val);
    };
    const [feedbackSuccessIsVisible, setFeedbackSuccessIsVisible] = React.useState<boolean>(false);
    return (
        <>
            <Modal setIsVisible={setIsVisible} isVisible={isVisible}>
                <StyledModalContainer>
                    <StyledTitleContainer>How was your experience buying a top up today?</StyledTitleContainer>
                    <StyledFeedbackWrapper>
                        <FeedbackStarRatings onChange={(e) => handleStarClicked(e)} />
                    </StyledFeedbackWrapper>
                    <StyledContentText>Would you like to share anything with us?</StyledContentText>
                    <StyledInputWrapper>
                        <Input name='text' placeholder='Share Here' maxLen={1024} {...feedbackInput} />
                    </StyledInputWrapper>

                    <StyledButtonContainer>
                        <UserRegisteredButton
                            disabled={rating > 0 ? false : true}
                            onClick={() => (feedbackDetails.loading ? null : handleFeedbackSubmit())}
                        >
                            {feedbackDetails.loading ? <Loader inverted /> : 'Submit'}
                        </UserRegisteredButton>
                    </StyledButtonContainer>
                </StyledModalContainer>
            </Modal>
            <FeedbackSuccessModal isVisible={feedbackSuccessIsVisible} setIsVisible={setFeedbackSuccessIsVisible} />
        </>
    );
};

export default FeedbackModal;
