import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTopMargin, StyledContent, StyledErrorText, StyledRecentText } from './styles';
import { clearUserError, loginWithMobile, verifyUserOTP } from '../../../redux/slices/UserSlice';
import { Button, Loader } from '../../atoms';
import { LoginVerify } from '../../containers';
import { IOTPPage } from './types';
import { FirebaseCaptcha } from '../../../../../../adapters/provider';
import UnRegisteredUsers from '../../containers/UnRegisteredUsers';

const OTPPage: React.FunctionComponent<IOTPPage> = ({ mobile, goBack }) => {
    const dispatch = useDispatch();
    const [isVisible, setIsVisible] = React.useState<boolean>(false);
    const [otp, setOtp] = React.useState('');
    const userData = useSelector((state: any) => state.TopUp_user.userData);
    const enteredMobile = useSelector((state: any) => state.TopUp_user.userMobile.data || '');
    const goBackClick = () => {
        goBack();
    };
    React.useEffect(() => {
        if (otp.length === 6) {
            document.addEventListener('keypress', handleEnterKey);
            return () => {
                document.removeEventListener('keypress', handleEnterKey);
            };
        }
    }, [otp]);

    const handleEnterKey = (e: any): void => {
        if (otp.length === 6 && e.keyCode === 13) {
            verifyOTP();
        }
    };
    const verifyOTP = () => {
        dispatch(clearUserError());
        if (window.confirmationResult?.confirm && otp) {
            dispatch(verifyUserOTP({ otp, enteredMobile }));
        } else {
            goBackClick();
        }
    };
    const resendOTP = () => {
        dispatch(clearUserError());
        window.recaptchaVerifier = new FirebaseCaptcha.RecaptchaVerifier('resend-otp', {
            size: 'invisible'
        });
        if (mobile) dispatch(loginWithMobile(mobile));
    };
    React.useEffect(() => {
        if (userData.error) {
            if (userData.error.message === 'User not found in records') {
                setIsVisible(true);
            }
        }
    }, [userData]);
    return (
        <StyledContent>
            <LoginVerify showMobileForm={true} phoneNumber={mobile} getOtp={setOtp} goBack={goBack} />
            {userData.error && <StyledErrorText>{userData.error.message}</StyledErrorText>}
            {userData.error && <UnRegisteredUsers isVisible={isVisible} setIsVisible={setIsVisible} />}
            <StyledTopMargin>
                <Button type='Otp' disabled={!(otp.length === 6)} onClick={verifyOTP} fullWidth={true}>
                    {userData.loading ? <Loader inverted /> : 'Submit'}
                </Button>
                <StyledRecentText onClick={resendOTP} id={'resend-otp'}>
                    Resend OTP →
                </StyledRecentText>{' '}
            </StyledTopMargin>
        </StyledContent>
    );
};

export default OTPPage;
