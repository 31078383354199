import styled from 'styled-components';

export const StyledPrimaryButton = styled.button<{
    fullWidth: boolean;
    disabled: boolean;
    $color?: string;
}>(
    (p) => `
    padding: 11px 10px;
    background-color: ${p.$color ? p.$color : p.theme.colors.primary};
    color: ${p.theme.colors.white};
    font-weight: 700;
    border-radius: 64px;
    font-size: ${p.theme.fontSizes.body};
    border: 0px;
    ${p.fullWidth ? 'width: 100%;' : ''}
    ${p.disabled ? 'opacity: 0.1;' : ''}
    font-family:'Work Sans';
    min-height:55px;
    display:flex;
    align-items: center;
    justify-content: center;
`
);

export const StyledOtpButton = styled.button<{
    fullWidth: boolean;
    disabled: boolean;
}>(
    (p) => `
    padding: 11px 10px;
    color: ${p.disabled ? '#717171' : p.theme.colors.loopEmerald};
    font-weight: 700;
    border-radius: 16px;
    cursor:pointer;
    font-size: ${p.theme.fontSizes.body};
    border: 0px;
    font-family:'Work Sans';
    ${p.fullWidth ? 'width: 100%;' : ''}
    ${p.disabled ? 'opacity: 0.8;' : ''}
    background-color: ${p.disabled ? '#D7D7D7' : p.theme.colors.coverUpGreen};
    min-height:55px;
    display:flex;
    align-items: center;
    justify-content: center;
`
);

export const StyledSecondaryButton = styled.button<{
    fullWidth: boolean;
    disabled: boolean;
    $color?: string;
    $borderRadius?: string;
    $fontColor?: string;
    $border?: string | '0px';
}>(
    (p) => `
    padding: 18px 43px;
    background-color: ${p.theme.colors.primary};
    color: ${p.$fontColor ? p.$fontColor : p.theme.colors.white};
    font-weight: bold;
    border-radius: ${p.$borderRadius ? p.$borderRadius : 6};
    font-size: ${p.theme.fontSizes.mediumFont};
    border: ${p.$border};
    ${p.fullWidth ? 'width: 100%;' : ''}
    ${p.disabled ? 'opacity: 0.1;' : ''}
    cursor: pointer;
    background-color: ${p.$color ? p.$color : p.theme.colors.primary};
    flex-wrap: wrap;
    flex-direction: row;
    font-family:'Work Sans';
`
);

export const StyledOAuthButton = styled.button<{
    fullWidth: boolean;
    disabled: boolean;
}>(
    (p) => `
    font-size: ${p.theme.fontSizes.mediumFont};
    padding: 15px 24px;
    width: 278px;
    max-width: 100%;
    font-weight: 500;
    ${p.fullWidth ? 'width: 100%;' : ''}
    ${p.disabled ? 'opacity: 0.1;' : ''}
    background-color: ${p.theme.colors.white};
    border: 0;
    // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #595959;
    font-family:'Work Sans';
`
);
export const StyledOAuthIcon = styled.img`
    height: 24px;
    width: auto;
    overflow: visible;
    max-height: 100%;
    margin-right: 15px;
    font-family: 'Work Sans';
`;
export const StyledOAuthText = styled.span`
    /* opacity: 0.6; */
`;

export const StyledIconButton = styled.button<{
    fullWidth: boolean;
    disabled: boolean;
}>(
    (p) => `
    padding: 10px 9px;
    height: 45px;
    width: 42px;
    max-height: 45px;
    max-width: 42px;
    background-color: ${p.theme.colors.white};
    border-radius: 8px;
    border: 1px solid ${p.theme.colors.border};
    box-shadow: 0px 4px 10px rgba(149, 159, 190, 0.2);
    ${p.fullWidth ? 'width: 100%;' : ''}
    ${p.disabled ? 'opacity: 0.1;' : ''}
    font-family:'Work Sans';
`
);
