import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    @media (max-width: 768px) {
        /* padding: 20px; */
        height: 100vh;
        max-width: 100%;
        padding: 0px;
    }
`;

export const StyledSectionContainer = styled.div`
    display: flex;
    background: ${(p) => p.theme.colors.white};
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

export const StyledMainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
`;
export const StyledRightContainer = styled.div`
    display: flex;
    width: 67%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    background: ${(p) => p.theme.colors.white};
    @media (max-width: 768px) {
        width: 100%;
        text-align: center;
        padding: 10px;
    }
`;

export const StyledHeadingContainer = styled.div(
    (p) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${p.theme.colors.white};
    margin-top:50px;
`
);

export const StyledHeadingText = styled.h4(
    (p) => `
    font-weight: 500;
    font-size: ${p.theme.fontSizes.landing};
    color: ${p.theme.colors.loopEmerald};
    margin: 0px;
`
);

export const StyledSignInText = styled.span(
    (p) => `
    color: ${p.theme.colors.purple};
    font-weight: 500;
    font-size: ${p.theme.fontSizes.landing};
`
);

export const StyledSignInSubText = styled.label`
    letter-spacing: -0.02em;
    font-weight: 400;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 30px;
`;

export const StyledPhoneNumContainer = styled.div<{ $padding?: string }>(
    (p) => `
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: ${p.$padding || '20px 0px'};
    width:100%;
    background-color: ${p.theme.colors.white};
`
);

export const StyledFooterContainer = styled.div(
    (p) => `
    display: flex;
    background-color: #F2F7F6;
    // background-color: ${p.theme.colors.white};
    margin-top:auto;
    padding:20px 0px;
    width:100%;
    justify-content:center;
`
);

export const StyledFooterText = styled.span(
    (p) => `
    color: #595959;
    font-weight: 400;
    font-size: ${p.theme.fontSizes.label};
`
);
