import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import DowntimePage from './components/pages/DowntimePage';
import MainRouter from './components/pages/router';
import { ReduxState } from './redux';
import { SegmentProvider } from './utils/hooks/useSegment';
import { ToastProvider } from './utils/hooks/useToast';
// import { useDispatch } from 'react-redux';
// import { fetchTopUpPolicyDetails } from './redux/slices/PolicyListSlice';
const isMaintainceMode = false;
const StyledContainer = styled.div`
    height: 100vh;
    width: 100vw;
    max-height: 100vh;
    max-width: 100vw;
    background-color: ${(p) => p.theme.colors.loopEmerald};
`;

const App: React.FunctionComponent = () => {
    // const dispatch = useDispatch();
    const companyId = useSelector((state: ReduxState) => state.TopUp_user.userData.data?.employer || '');
    const loginMethod = useSelector((state: ReduxState) => state.TopUp_user.userData.data?.loginMethod);
    const user = useSelector((state: ReduxState) => state.TopUp_user.userData.data || '');
    // TODO: temp Moved to dashboard
    // const policyId = `${process.env.REACT_APP_TOPUP_POLICY_ID}`;
    // React.useEffect(() =>  {
    //     if (policyId) {
    //         dispatch(fetchTopUpPolicyDetails({ policyId }));
    //     }
    // }, [policyId]);

    return isMaintainceMode ? (
        <DowntimePage />
    ) : (
        <ToastProvider>
            <SegmentProvider
                value={{
                    companyId: companyId || '',
                    userId: user.userId || '',
                    commonMeta: {
                        gender: user.gender || '',
                        email: user.email || '',
                        phone: user.mobile || '',
                        login_method: loginMethod || '',
                        login_application: process.env.REACT_APP_NAME
                    }
                }}
            >
                <StyledContainer>
                    <MainRouter />
                </StyledContainer>
            </SegmentProvider>
        </ToastProvider>
    );
};

export default App;
