import React from 'react';
import { CoveredMemberContainer, MemberNameContainer, StyledRelationText, StyledNameText } from './styles';
import { IMemberIdentity } from './types';
import { NameIcon } from '..';
import { colorLibrary } from '../../../theme';
import { capitalizeFirstLetter } from '../../../utils/common';
const MemberIdentity: React.FunctionComponent<IMemberIdentity> = ({
    firstName,
    lastName,
    relationship,
    index,
    bgColor
}) => {
    return (
        <CoveredMemberContainer $color={bgColor || ''}>
            <NameIcon name={firstName + ' ' + lastName} color={colorLibrary[index % colorLibrary.length]} />
            <MemberNameContainer>
                <StyledNameText>
                    {capitalizeFirstLetter(firstName || '') + ' ' + capitalizeFirstLetter(lastName || '')}
                </StyledNameText>
                <StyledRelationText>{capitalizeFirstLetter(relationship || '')}</StyledRelationText>
            </MemberNameContainer>
        </CoveredMemberContainer>
    );
};

export default MemberIdentity;
