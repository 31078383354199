import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './redux/store';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './redux';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import { Loader } from './components/atoms';
import App from './App';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { initializeSegment } from './segment';
import firebase from 'firebase';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

declare global {
    interface Window {
        recaptchaVerifier: firebase.auth.RecaptchaVerifier;
        confirmationResult: firebase.auth.ConfirmationResult | null;
        analytics: any;
    }
}
if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
        environment: process.env.NODE_ENV
    });
}
initializeSegment();
ReactDOM.render(
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <ReduxProvider store={store}>
                <PersistGate loading={<Loader />} persistor={persistor}>
                    <App />
                </PersistGate>
            </ReduxProvider>
        </ThemeProvider>
    </BrowserRouter>,
    document.getElementById('root')
);
