import React, { useEffect } from 'react';
import { StyledSection } from './styles';
import { useDispatch } from 'react-redux';
import { Header } from '../../containers';
import { LoopIconGreen } from '../../../assets/img';
import { fetchFaqsData } from '../../../redux/slices/TopUpLandingSlice/thunk';
import { getUserDetails } from '../LoginContainer/redux/slices/UserSlice';
import firebase from 'firebase';
const TopupInfo = React.lazy(() => import('./../../containers/TopupInfo'));
const TopupBenefits = React.lazy(() => import('./../../containers/TopupBenefits'));
const TopupDifferences = React.lazy(() => import('./../../containers/TopupDifferences'));
const TopupScenario = React.lazy(() => import('./../../containers/TopupScenario'));
const TopupSelectPlan = React.lazy(() => import('./../../containers/TopupSelectPlan'));
const TermsFooter = React.lazy(() => import('../../atoms/TermsFooter'));
const TopupInfoSection = React.lazy(() => import('../../containers/TopupInfoSection'));
const TopupInfoWidget = React.lazy(() => import('../../containers/TopupInfoWidget'));
const TopupCostSection = React.lazy(() => import('../../containers/TopupCostSection'));
const TopUpFaqContainer = React.lazy(() => import('../../containers/TopUpFaqContainer'));
const TopupTestimonialSection = React.lazy(() => import('../../containers/TopupTestimonialSection'));
const Footer = React.lazy(() => import('../../containers/Footer'));
const TopupLandingPage: React.FunctionComponent = (): React.ReactElement => {
    const dispatch = useDispatch();
    const selectTopupPlanRef = React.useRef<HTMLDivElement>(null);
    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                const userMobile = user.phoneNumber?.slice(-10);
                const userEmail = user.email;
                if (userEmail) {
                    dispatch(getUserDetails({ key: 'email', value: userEmail }));
                } else if (userMobile) {
                    dispatch(getUserDetails({ key: 'mobile', value: userMobile }));
                }
            }
        });
        dispatch(fetchFaqsData());
    }, []);
    const scrollIntoSelectTopups = () => {
        document.body.style.overflow = 'auto';
        selectTopupPlanRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <>
            <Header bgColor={true} logo={LoopIconGreen} />
            <TopupInfo onClickExplore={scrollIntoSelectTopups} />
            <StyledSection>
                <TopupInfoWidget />
            </StyledSection>
            <TopupCostSection />
            <TopupBenefits onClickExplore={scrollIntoSelectTopups} />
            <TopupScenario onClickExplore={scrollIntoSelectTopups} />
            <TopupSelectPlan scrollRef={selectTopupPlanRef} />
            <TopupInfoSection />
            <TopupDifferences />
            <TopupTestimonialSection />
            <TopUpFaqContainer />
            <Footer />
            <TermsFooter />
        </>
    );
};
export default TopupLandingPage;
