import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { StyledContainer, Signout, StyledLogo, ButtonsContainer, SaveButton } from './styles';
import { LoopGreenLogo } from '../../../assets/img';
import { IHeader } from './types';
import { FirebaseAuth } from '../../../adapters/provider';
import { logoutUser as resetUserData } from '../../pages/LoginContainer/redux/slices/UserSlice';
import { resetState as resetApplicationData } from '../../../redux/slices/ApplicationStateSlice';
import { MAIN_ROUTES } from '../../../utils/constants/RouteConstants';
import { clearStates as resetPolicyData } from '../../../redux/slices/PolicyListSlice';
import { ReduxState } from '../../../redux';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import TopUpModal from '../TopUpModal';
import { TextView } from '../../atoms/TextView';
import theme from '../../../theme';
import { capitalizeFirstLetter } from '../../../utils/common';
const Header: React.FunctionComponent<IHeader> = ({ bgColor, logo }) => {
    const userName = useSelector((state: ReduxState) => state.TopUp_user.userData.data);
    const [showTopUp, setShowTopUp] = React.useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const trackClick = useSegment('click');
    const trackLogout = useSegment('identify');
    const onClickTalktoUs = () => {
        window.scrollTo(0, 0);
        trackClick(SEGMENT_ACTIONS.CLICK.TALK_TO_US);
        setShowTopUp(true);
    };
    const logout = () => {
        trackClick(SEGMENT_ACTIONS.CLICK.NAVIGATION_HEADER_SIGNOUT);
        FirebaseAuth.signOut();
        trackLogout({
            name: SEGMENT_ACTIONS.IDENTIFY.USER_LOGOUT.name
        });
        dispatch(resetApplicationData());
        dispatch(resetPolicyData());
        dispatch(resetUserData());
        history.push(MAIN_ROUTES.topUpLanding);
    };
    const redirectToDashboard = () => {
        history.push(MAIN_ROUTES.topUpLanding);
    };
    return (
        <StyledContainer $bgColor={bgColor}>
            <StyledLogo src={logo || LoopGreenLogo} alt={'Loop Health Logo'} onClick={redirectToDashboard} />
            <ButtonsContainer>
                {userName && (
                    <>
                        <TextView
                            fontSize='18px'
                            margin='0px 5px 0px 0px'
                            color={bgColor ? theme.colors.white : theme.colors.loopEmerald}
                        >
                            Hi, {capitalizeFirstLetter(userName?.firstName)}
                        </TextView>
                        <Signout $bgColor={bgColor} onClick={logout}>
                            Sign Out
                        </Signout>
                    </>
                )}
                <SaveButton onClick={onClickTalktoUs}>{'Talk to Us'}</SaveButton>
            </ButtonsContainer>
            <TopUpModal visible={showTopUp} setVisible={setShowTopUp} />
        </StyledContainer>
    );
};

export default Header;
