import React from 'react';
interface ISegmentValues {
    userId: string;
    companyId: string;
    commonMeta?: Record<string, unknown>;
}
const initialState = {
    userId: '',
    companyId: ''
} as ISegmentValues;

const SegmentContext = React.createContext<ISegmentValues>(initialState);

type ISegmentType = 'page' | 'click' | 'identify';
const useSegment = (
    type: ISegmentType,
    commonProperties?: Record<string, unknown>
): (({ name, properties }: { name: string; properties?: Record<string, unknown> }) => void) => {
    const userAgent = window.navigator.userAgent;
    const { userId, companyId, commonMeta = {} } = React.useContext<ISegmentValues>(SegmentContext);

    if (type === 'page') {
        return ({ name, properties }: { name: string; properties?: Record<string, unknown> }) => {
            window.analytics.page({
                ...properties,
                ...commonProperties,
                ...commonMeta,
                type: type,
                path: 'Page',
                name: name,
                user_id: userId,
                company_id: companyId,
                page_title: name,
                device_id: userAgent
            });
        };
    } else if (type === 'click') {
        return ({ name, properties }: { name: string; properties?: Record<string, unknown> }) =>
            window.analytics.track({
                event: 'ClickedOn',
                type,
                properties: {
                    ...properties,
                    ...commonProperties,
                    ...commonMeta,
                    user_id: userId,
                    company_id: companyId,
                    button_name: name,
                    form_name: name,
                    device_id: userAgent
                }
            });
    } else if (type === 'identify') {
        return ({ name, properties }: { name: string; properties?: Record<string, unknown> }) =>
            window.analytics.identify(userId, {
                traits: {
                    ...properties,
                    ...commonProperties,
                    ...commonMeta,
                    name: name,
                    company_id: companyId,
                    device_id: userAgent
                },
                userId: userId
            });
    }
    return () => null;
};

export const SegmentProvider = SegmentContext.Provider;
export type { ISegmentValues };
export default useSegment;
