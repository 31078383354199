import React from 'react';
import {
    StyledContainer,
    StyledTitleContainer,
    StyledSubTitle,
    StyledButtonContainer,
    UserRegisteredButton,
    UserNotRegisteredButton
} from './styles';
import { IUnRegisteredUsers } from './types';
import { Modal } from '../../../../../atoms';
const UnRegisteredUsers: React.FunctionComponent<IUnRegisteredUsers> = ({ setIsVisible, isVisible }) => {
    const [showRegisteredModal, setShowRegisteredModal] = React.useState<boolean>(false);
    const handleNotRegisteredBtn = () => {
        // Redirect
        setIsVisible(!isVisible);
    };

    const handleRegisteredBtn = () => {
        setShowRegisteredModal(true);
    };
    // useState
    return (
        <Modal setIsVisible={setIsVisible} isVisible={isVisible}>
            {showRegisteredModal ? (
                <StyledContainer>
                    <StyledTitleContainer>Awesome! Please try using a different login method</StyledTitleContainer>
                    <StyledSubTitle>
                        Please try using your work email ID/a different phone number registered with Loop by your HR
                    </StyledSubTitle>
                    <StyledButtonContainer>
                        <UserRegisteredButton onClick={() => setIsVisible(!isVisible)}>Got it!</UserRegisteredButton>
                    </StyledButtonContainer>
                </StyledContainer>
            ) : (
                <StyledContainer>
                    <StyledTitleContainer>
                        Oops! Looks like this contact is not registered with Loop
                    </StyledTitleContainer>
                    <StyledSubTitle>Has your company registered you with Loop yet?</StyledSubTitle>
                    <StyledButtonContainer>
                        <UserRegisteredButton onClick={handleRegisteredBtn}>
                            Yes, I’m a registered Loop Member
                        </UserRegisteredButton>
                        <UserNotRegisteredButton onClick={handleNotRegisteredBtn}>
                            No, I’m new to Loop
                        </UserNotRegisteredButton>
                    </StyledButtonContainer>
                </StyledContainer>
            )}
        </Modal>
    );
};

export default UnRegisteredUsers;
