import { createAsyncThunk } from '@reduxjs/toolkit';
// import { setSelectedMemberTopUp } from '.';
import {
    fetchPolicyDetailsByID,
    fetchPolicyWiseFamilyDetails,
    fetchTopPolicyDetailsByID,
    calculateAP,
    fetchPolicyBenefits,
    createOrder,
    verifyPayment,
    saveFeedback,
    enrolUserToPolicy,
    generateEcards
} from '../../../adapters';
import { IAdapterData } from '../../types';
import { IMemberTopUpData } from './types';
const USER_POLICY_ACTION_TYPES = {
    FETCH_POLICY_LIST: 'userpolicylist/fetch-policy-list-topup',
    FETCH_USER_DEPENDENTS: 'userpolicylist/fetchUserDependents',
    FETCH_POLICY_BENEFITS: 'userpolicylist/fetch-policy-benefits',
    FETCH_POLICY_DETAILS: 'userpolicylist/fetch-policy-details',
    FETCH_TOP_UP_POLICY_DETAILS: 'userpolicylist/fetch-top-up-policy-details',
    CALCULATE_PREMIUM: 'userpolicylist/calculate-premium',
    CREATE_ORDER: 'userpolicylist/create-order',
    FETCH_ORDER_STATUS: 'userpolicylist/order-details',
    SAVE_FEEDBACK: 'userpolicylist/save-feedback',
    ENROLL_USER: 'userpolicylist/enroll-user',
    GENERATE_ECARD: 'userpolicylist/generate-ecards'
};

export const fetchUserPolicyList = createAsyncThunk(
    USER_POLICY_ACTION_TYPES.FETCH_POLICY_LIST,
    async ({ userId }: { userId: string }) => {
        try {
            const policyList = await fetchPolicyWiseFamilyDetails(userId);
            return {
                policyList: policyList
            };
        } catch (e) {
            throw e;
        }
    }
);

export const fetchPolicyDetails = createAsyncThunk(
    USER_POLICY_ACTION_TYPES.FETCH_POLICY_DETAILS,
    async ({ policyId }: { policyId: string }) => {
        const res = await fetchPolicyDetailsByID(policyId);
        return {
            policyDetails: res
        };
    }
);

export const fetchPolicyBenefitsDetails = createAsyncThunk(
    USER_POLICY_ACTION_TYPES.FETCH_POLICY_BENEFITS,
    async ({ policyId, slabId }: { policyId: string; slabId: string }) => {
        const res = await fetchPolicyBenefits(policyId, slabId);
        return {
            policyBenefits: res
        };
    }
);

export const fetchTopUpPolicyDetails = createAsyncThunk(
    USER_POLICY_ACTION_TYPES.FETCH_TOP_UP_POLICY_DETAILS,
    async ({ policyId }: { policyId: string }) => {
        const res = await fetchTopPolicyDetailsByID(policyId);
        return {
            policyDetails: res
        };
    }
);

export const calculatePremium = createAsyncThunk(
    USER_POLICY_ACTION_TYPES.CALCULATE_PREMIUM,
    async ({
        sumInsured,
        deductible,
        userIds,
        policyId
    }: {
        selfUserId: string;
        sumInsured: number;
        deductible: number;
        policyId: string;
        userIds: string[];
    }) => {
        const req = {
            sumInsured,
            deductible,
            userIds,
            policyId
        };
        try {
            const {
                premium,
                annualPremiumGST,
                loopMarginWithoutGST,
                digitPremiumWithoutGST,
                annualPremiumWithoutGST
            } = await calculateAP(req);

            const obj: IMemberTopUpData = {
                annualPremium: premium,
                annualPremiumGST,
                loopMarginWithoutGST,
                digitPremiumWithoutGST,
                annualPremiumWithoutGST,
                sumInsured,
                userIds,
                deductible
            };
            return {
                memberTopUp: {
                    loading: false,
                    data: obj,
                    error: null
                } as IAdapterData
            };
        } catch (e) {
            throw e;
        }
    }
);

export const createPaymentOrder = createAsyncThunk(
    USER_POLICY_ACTION_TYPES.CREATE_ORDER,
    async ({
        amount,
        premium,
        gst,
        userId,
        sumInsured,
        deductible,
        coveredMembers,
        digitPremium,
        loopCharges,
        policyId
    }: {
        amount: number;
        sumInsured: number;
        deductible: number;
        premium: number;
        gst: number;
        userId: string;
        digitPremium: number;
        loopCharges: number;
        coveredMembers: string[];
        policyId:string;
    }) => {
        const req = {
            source: 'topup',
            amount,
            premium,
            gst,
            userId,
            sumInsured,
            deductible,
            coveredMembers,
            digitPremium,
            loopCharges,
            policyId
        };
        try {
            const response = await createOrder(req);
            return {
                paymentOrder: {
                    loading: false,
                    data: response,
                    error: null
                } as IAdapterData
            };
        } catch (e) {
            throw e;
        }
    }
);

export const fetchOrderStatus = createAsyncThunk(
    USER_POLICY_ACTION_TYPES.FETCH_ORDER_STATUS,
    async ({
        orderCreationId,
        razorpayPaymentId,
        razorpayOrderId,
        razorpaySignature
    }: {
        orderCreationId: string;
        razorpayPaymentId: string;
        razorpayOrderId: string;
        razorpaySignature: string;
    }) => {
        const req = {
            orderCreationId,
            razorpayPaymentId,
            razorpayOrderId,
            razorpaySignature
        };
        const res = await verifyPayment(req);
        return {
            paymentOrderStatus: {
                loading: false,
                data: res,
                error: null
            } as IAdapterData
        };
    }
);

export const createFeedback = createAsyncThunk(
    USER_POLICY_ACTION_TYPES.SAVE_FEEDBACK,
    async ({
        taskRating,
        patientId,
        feedbackText
    }: {
        taskRating: number;
        patientId: string;
        feedbackText: string;
    }) => {
        const req = {
            taskType: 'topup',
            taskRating,
            feedbackText,
            patientId
        };
        try {
            const response = await saveFeedback(req);
            return {
                feedbackDetails: {
                    loading: false,
                    data: response,
                    error: null
                } as IAdapterData
            };
        } catch (e) {
            throw e;
        }
    }
);

export const enrolUserPolicy = createAsyncThunk(
    USER_POLICY_ACTION_TYPES.ENROLL_USER,
    async ({
        userId,
        policyId,
        slabId,
        dependentIds
    }: {
        userId: string;
        policyId: string;
        slabId: string;
        dependentIds: string[];
    }) => {
        try {
            const req = {
                enrolmentCycle: 'At Inception',
                enrolmentStatus: 'PENDING',
                policyStatus: 'ENROLED',
                generateEcardFlag: true,
                dependentIds,
                slabId
            };
            const response = await enrolUserToPolicy(req, userId, policyId);
            return {
                enrolUser: {
                    loading: false,
                    data: response,
                    error: null
                } as IAdapterData
            };
        } catch (e) {
            throw e;
        }
    }
);

export const generateUserEcards = createAsyncThunk(
    USER_POLICY_ACTION_TYPES.GENERATE_ECARD,
    async ({ memberData, policyId }: { memberData: any; policyId: string }) => {
        try {
            const response = await generateEcards(memberData, policyId);
            return {
                generateEcards: {
                    loading: false,
                    data: response,
                    error: null
                } as IAdapterData
            };
        } catch (e) {
            throw e;
        }
    }
);
