/* eslint-disable max-len */
import React from 'react';
import {
    StyledContainer,
    StyledHeadingContainer,
    StyledWrapperContainer,
    StyledTopUpContainer,
    StyledInternalTopContainer,
    StyledInsuranceContainer,
    StyledAPContainer,
    StyledAPContainerSpan,
    StyledPaddingContainer,
    StyledDivider,
    StyledDividerSpan,
    StyledDetailsWrapper,
    StyledSIContainer,
    SIDetailsWrapper,
    StyledDaysLeftContainer,
    StyledSup,
    StyledSub,
    StyledLoaderWrapper,
    ErrorContainer,
    WrapperContainer
} from './styles';
import { shapeRight, TimerIcon } from '../../../assets/img';
import { ITopUpAvailable } from './types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Loader } from '../../atoms';
import theme from '../../../theme';
import { ReduxState } from '../../../redux';

import { enrollmentDaysLeft, getDeductible, getSegmentPageName, getSumInsured } from '../../../utils/common';
import { calculatePremium } from '../../../redux/slices/PolicyListSlice/thunks';
import Footer from '../Footer';
import { INTERNAL_ROUTES } from '../../../utils/constants/RouteConstants';
import { Header } from '..';
import { LoopIconGreen } from '../../../assets/img';
import useSegment from '../../../utils/hooks/useSegment';
import { SEGMENT_ACTIONS } from '../../../utils/constants/SegmentActionConstants';
import { resetCalculatedPremium, resetOrderState } from '../../../redux/slices/PolicyListSlice';
import { TextView } from '../../atoms/TextView';
import { ImageView } from '../../atoms/ImageView';
import TopUpUnAvailableInfo from '../../atoms/TopUpUnAvailableInfo';

const TopUpFaqContainer = React.lazy(() => import('../TopUpFaqContainer'));
const TopupCustomization = React.lazy(() => import('../TopupCustomization'));
const InsuranceIdentity = React.lazy(() => import('../../atoms/InsuranceIdentity'));
const CheckoutFooter = React.lazy(() => import('../../atoms/CheckoutFooter'));
const RecommendationBenefitsCovered = React.lazy(() => import('../RecommendationBenefitsCovered'));
const RecommendationBenefitsNotCovered = React.lazy(() => import('../RecommendationBenefitsNotCovered'));
const RecommendationAdditionalBenefits = React.lazy(() => import('../RecommendationAdditionalBenefits'));

const TopUpAvailable: React.FunctionComponent<ITopUpAvailable> = ({ policyData }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const trackPage = useSegment('page');
    const location = useLocation();
    const selfUserId = useSelector((state: ReduxState) => state.TopUp_user.userData.data.userId);
    const topUpAmount: any = useSelector((state: ReduxState) => state.TopUp_policyData.memberTopUp);
    const isLoadingTopUpAmount = useSelector((state: ReduxState) => state.TopUp_policyData.memberTopUp?.loading);
    const topupPolicyData = useSelector((state: ReduxState) => state.TopUp_policyData.topupPolicyDetails.data);
    const [selectedSI, setSelectedSI] = React.useState('');
    const [sumInsuredSlabs, setSumInsuredSlabs] = React.useState<number[]>([]);
    const [selectedMembers, setSelectedMembers] = React.useState<string[]>([]);
    const [totalSI, setTotalSI] = React.useState('');
    const [familyStructure, setFamilyStructure] = React.useState('');
    const selectedDeductible = getDeductible(policyData.sumInsured);
    const daysLeft = enrollmentDaysLeft(policyData.policyStartDate, topupPolicyData?.enrolmentThreshold);
    const trackClick = useSegment('click');
    const handleProceedBtn = () => {
        const segmentAction = SEGMENT_ACTIONS.CLICK.PROCEED_TO_BUY_RECOMMENDATION;
        trackClick({
            name: segmentAction.name,
            properties: {
                user_id: (segmentAction.properties?.user_id as (userId: string) => string)(selfUserId),
                sum_insured: (segmentAction.properties?.sum_insured as (sumInsured: string) => string)(
                    selectedSI.toString()
                ),
                selected_members: (segmentAction.properties?.selected_members as (selectedMembers: string) => string)(
                    selectedMembers.toString()
                ),
                deductible: (segmentAction.properties?.deductible as (deductible: string) => string)(
                    selectedDeductible.toString()
                ),
                annual_premium: (segmentAction.properties?.selected_members as (annualPremium: string) => string)(
                    topUpAmount?.annualPremium
                )
            }
        });
        dispatch(resetOrderState());
        history.push(INTERNAL_ROUTES.summary);
    };

    React.useEffect(() => {
        if (topUpAmount) {
            const currentPageAction = SEGMENT_ACTIONS.PAGE[getSegmentPageName(location.pathname) as any];
            trackPage({
                name: 'PROCEED_TO_BUY_RECOMMENDATION',
                properties: {
                    page_url:
                        (currentPageAction.properties?.page_url as (path: string) => string)(location.pathname) || '',
                    page_id:
                        (currentPageAction.properties?.page_id as (path: string) => string)(location.pathname) || '',
                    selected_members: selectedMembers,
                    sum_insured: selectedSI,
                    deductible: selectedDeductible,
                    annual_premium: topUpAmount?.annualPremium || 0
                }
            });
        }
    }, [topUpAmount]);

    React.useEffect(() => {
        const users: string[] = [];
        policyData.dependents.forEach((user) => {
            users.push(user.userId);
        });
        setSelectedMembers(users);

        const applicableSI = topupPolicyData?.slabs
            .filter((slab) => (slab.deductible as number) == selectedDeductible)
            .map((item: any) => item.sumInsured)
            .filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index)
            .sort(function(a: number, b: number) {
                return a - b;
            });
        let SI: any;
        if (applicableSI?.length) {
            SI = applicableSI[0];
            setSelectedSI(SI);
            setSumInsuredSlabs(applicableSI);
            const selectedSlabs = topupPolicyData?.slabs.find(
                (slab) => (slab.deductible as number) == selectedDeductible && (slab.sumInsured as number) == SI
            );
            setFamilyStructure(selectedSlabs?.familyDefinition ?? '');
        }
        setTotalSI(SI + policyData.sumInsured);
    }, [topupPolicyData]);

    React.useEffect(() => {
        if (selectedSI && selectedMembers.length) {
            dispatch(resetCalculatedPremium());
            dispatch(
                calculatePremium({
                    selfUserId,
                    sumInsured: parseInt(selectedSI),
                    deductible: selectedDeductible,
                    userIds: selectedMembers,
                    policyId: `${process.env.REACT_APP_TOPUP_POLICY_ID}`
                })
            );
        }
    }, [selectedMembers, selectedSI]);

    return (
        <>
            {policyData.sumInsured < 500000 ? (
                <ErrorContainer>
                    <Header logo={LoopIconGreen} bgColor={false} />
                    <WrapperContainer>
                        <TopUpUnAvailableInfo
                            title={
                                <TextView fontSize={'42px'} color={theme.colors.loopEmerald}>
                                    Thanks for your interest, it’s been
                                    <TextView fontSize={'42px'} color={theme.colors.purple} margin={'0px 0px 0px 10px'}>
                                        recorded!
                                    </TextView>
                                </TextView>
                            }
                            // eslint-disable-next-line max-len
                            description='Super Top-up plans are current not available for your organisation. Our team is working hard to ensure you get access to additional health cover as soon as possible.'
                            imageSrc={shapeRight}
                        />
                    </WrapperContainer>
                </ErrorContainer>
            ) : (
                <>
                    <StyledContainer>
                        <Header logo={LoopIconGreen} bgColor={true} />
                        <StyledWrapperContainer>
                            <StyledHeadingContainer>
                                <TextView margin='10px 0px 25px 0px' fontSize='34px' color={theme.colors.white}>
                                    <TextView margin='10px 0px 0px 0px' fontSize='34px' color={theme.colors.planRed}>
                                        {' Customize '}
                                    </TextView>
                                    your top-up coverage!
                                </TextView>
                            </StyledHeadingContainer>
                            <StyledTopUpContainer>
                                <StyledInternalTopContainer
                                    $borderRadius={daysLeft < 10 ? '16px 16px 0px 0px' : '16px 16px 16px 16px'}
                                >
                                    <StyledInsuranceContainer>
                                        <InsuranceIdentity
                                            policyCategory={'Loop Super Top-up Policy'}
                                            policyName={'ICICI Lombard General Insurance Co. Ltd.  '}
                                            policyImage={topupPolicyData?.policyLogo || ''}
                                            textColor={'white'}
                                        />
                                        <StyledDetailsWrapper>
                                            <StyledSIContainer>
                                                <TextView
                                                    fontSize='16px'
                                                    margin='0px 0px 15px 0px'
                                                    color={theme.colors.white}
                                                    fontWeight='500'
                                                >
                                                    Top up Sum Insured
                                                </TextView>

                                                <SIDetailsWrapper>
                                                    <TextView
                                                        fontSize='23px'
                                                        margin='0px 0px 0px 0px'
                                                        color={theme.colors.white}
                                                        fontWeight='500'
                                                    >
                                                        ₹{getSumInsured(selectedSI)}
                                                    </TextView>
                                                </SIDetailsWrapper>
                                            </StyledSIContainer>
                                            <StyledDivider>
                                                <StyledDividerSpan />
                                            </StyledDivider>
                                            <StyledAPContainer>
                                                <TextView
                                                    fontSize='16px'
                                                    margin='0px 0px 0px 0px'
                                                    color={theme.colors.white}
                                                    fontWeight='500'
                                                >
                                                    Total Annual Premium
                                                </TextView>
                                                <StyledAPContainerSpan>
                                                    {isLoadingTopUpAmount ? (
                                                        <StyledLoaderWrapper>
                                                            <Loader inverted={true} />
                                                        </StyledLoaderWrapper>
                                                    ) : (
                                                        <>
                                                            <StyledSup>₹</StyledSup>
                                                            {topUpAmount?.annualPremium
                                                                ?.toString()
                                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',') || 0}
                                                            <StyledSub>/year</StyledSub>
                                                        </>
                                                    )}
                                                </StyledAPContainerSpan>
                                            </StyledAPContainer>
                                        </StyledDetailsWrapper>
                                    </StyledInsuranceContainer>
                                </StyledInternalTopContainer>
                                {daysLeft < 11 && (
                                    <StyledDaysLeftContainer>
                                        <ImageView
                                            width='14px'
                                            containerWidth='36px'
                                            containerMinHeight='36px'
                                            backgroundColor={theme.colors.planRed}
                                            src={TimerIcon}
                                            radius='22.5px'
                                        />
                                        <TextView margin='0px 0px 0px 16px' fontSize='14px' color={theme.colors.white}>
                                            {daysLeft}
                                            {' days left until you can buy this top up!'}
                                        </TextView>
                                    </StyledDaysLeftContainer>
                                )}

                                <StyledHeadingContainer>
                                    <TextView
                                        margin='40px 0px 28px 0px'
                                        fontSize='20px'
                                        color={theme.colors.teamTurquoise}
                                    >
                                        Scroll down for more details ↓
                                    </TextView>
                                </StyledHeadingContainer>
                            </StyledTopUpContainer>
                        </StyledWrapperContainer>
                    </StyledContainer>
                    <TopupCustomization
                        policyData={policyData}
                        selectedSI={selectedSI}
                        setSelectedSI={setSelectedSI}
                        totalSI={totalSI}
                        setTotalSI={setTotalSI}
                        familyStructure={familyStructure}
                        setFamilyStructure={setFamilyStructure}
                        selectedMembers={selectedMembers}
                        setSelectedMembers={setSelectedMembers}
                        selectedDeductible={selectedDeductible}
                        sumInsuredSlabs={sumInsuredSlabs}
                    />

                    <RecommendationBenefitsCovered />
                    <RecommendationAdditionalBenefits />
                    <RecommendationBenefitsNotCovered />
                    <TopUpFaqContainer />
                    <StyledPaddingContainer>
                        <Footer />
                    </StyledPaddingContainer>
                    <CheckoutFooter
                        memberTopUpPremium={topUpAmount?.annualPremium || 0}
                        onSaveDetailsClick={handleProceedBtn}
                        loading={isLoadingTopUpAmount}
                        coveredMembers={selectedMembers.length}
                        disabled={isLoadingTopUpAmount ? true : topUpAmount?.annualPremium > 0 ? false : true}
                    />
                </>
            )}
        </>
    );
};

export default TopUpAvailable;
