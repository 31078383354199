import { combineReducers } from 'redux';
import { ApplicationStateSlice } from '../slices';
import { UserSlice } from '../../components/pages/LoginContainer/redux/slices';
import PolicyListSlice from '../slices/PolicyListSlice';
import topUpLandingPageSlice from '../slices/TopUpLandingSlice';
const rootReducer = combineReducers({
    TopUp_applicationState: ApplicationStateSlice,
    TopUp_user: UserSlice,
    TopUp_policyData: PolicyListSlice,
    TopUp_LandingPage: topUpLandingPageSlice
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
