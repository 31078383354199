import React from 'react';
import { Button } from '../../atoms';
import { useHistory } from 'react-router';
import {
    StyledContainer,
    StyledContainerWrapper,
    StyledLeftContainer,
    StyledRightContainer,
    StyledImage,
    ButtonContainer
} from './styles';
// import { IAllowedUsers } from './types';
import theme from '../../../../../../theme';
import { MAIN_ROUTES } from '../../../../../../utils/constants/RouteConstants';
import { shapeRight } from '../../../../../../assets/img';
import { TextView } from '../../../../../atoms/TextView';

const AllowedUsers: React.FunctionComponent = ({}) => {
    const history = useHistory();
    const redirectToHome = () => {
        history.push(MAIN_ROUTES.topUpLanding);
    };
    return (
        <StyledContainer>
            <StyledContainerWrapper>
                <StyledLeftContainer>
                    <TextView fontSize={'44px'} color={theme.colors.loopEmerald}>
                        Thanks for your interest, it’s been
                        <TextView fontSize={'44px'} color={theme.colors.purple} margin={'0px 0px 0px 10px'}>
                            recorded!
                        </TextView>
                    </TextView>
                    <TextView fontSize={'22px'} color={theme.colors.loopEmerald}>
                        We will be launching the Super Top-ups for your company soon. Please get in touch with your HR
                        for further information.
                    </TextView>

                    <ButtonContainer>
                        <div>
                            <Button
                                type='secondary'
                                fullWidth={false}
                                color={theme.colors.coverUpGreen}
                                onClick={redirectToHome}
                                borderRadius='16px'
                                fontColor={theme.colors.loopEmerald}
                            >
                                Go back to Home Page
                            </Button>
                        </div>
                    </ButtonContainer>
                </StyledLeftContainer>
                <StyledRightContainer>
                    <StyledImage src={shapeRight} />
                </StyledRightContainer>
            </StyledContainerWrapper>
        </StyledContainer>
    );
};

export default AllowedUsers;
