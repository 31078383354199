import styled from 'styled-components';

export const StyledContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const StyledContainerUNAvailable = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const StyledContainerAvailable = styled.div`
    width: 100%;
`;

export const StyledLoaderContainer = styled.div`
    position: absolute;
    top: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
`;
